import ReactGA from "react-ga4";

const initializeAnalytics = () => {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
  if (trackingId) {
    ReactGA.initialize(trackingId);
    console.log(`Google Analytics initialized with Tracking ID: ${trackingId}`);
  } else {
    console.error("Google Analytics Tracking ID is not defined!");
  }
};

export const logPageView = (path?: string) => {
  if (path) {
    ReactGA.send({ hitType: "pageview", page: path });
  } else {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }
};

export default initializeAnalytics;
