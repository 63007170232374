import { api } from "../AxiosInstance";

// Define service functions for general data related API calls
const TransporterService = {
  getMyTransporters: async () => {
    try {
      const response = await api.get("/fed/transporters");
      return response;
      // return response.data; // Return only the data payload
    } catch (error) {
      console.error("Error fetching transporters:", error);
      throw error; // Re-throw error for global handling if necessary
    }
  },
};

export default TransporterService;
