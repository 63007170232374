import React, { useEffect, useRef, useState } from "react";
import { loadGoogleMapsApi } from "../../utils/loadgoogleMapsApi";
import maptruck from "../../assets/maptruck.png";

const MapComponent = ({
  container,
  sourceName,
  destinationName,
  source,
  destination,
}: any) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [truckPosition, setTruckPosition] = useState({
    lat: container.tracking.TruckLatitude,
    lng: container.tracking.TruckLongitude,
  });
  const [truckMarker, setTruckMarker] = useState<google.maps.Marker | null>(
    null
  );
  const [truckInfoWindowOpen, setTruckInfoWindowOpen] = useState(true);

  useEffect(() => {
    const initializeMap = async () => {
      await loadGoogleMapsApi("AIzaSyCEZohML94bY36-YMhs6RAZsiJArcvjyaA");
      const mapInstance = new window.google.maps.Map(mapRef.current!, {
        center: source,
        zoom: 15,
        restriction: {
          latLngBounds: {
            north: 37.0841, // Northernmost point
            south: 6.4627, // Southernmost point
            east: 97.3954, // Easternmost point
            west: 68.1767, // Westernmost point
          },
          strictBounds: false, // Set to true if you want to restrict panning
        },
      });
      setMap(mapInstance);

      // Source Marker
      const sourceMarker = new window.google.maps.Marker({
        position: source,
        map: mapInstance,
        title: sourceName,
        icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
      });

      const sourceInfoWindow = new window.google.maps.InfoWindow({
        content: `<div><strong>Source: ${sourceName}</strong></div>`,
      });

      sourceMarker.addListener("mouseover", () => {
        sourceInfoWindow.open(mapInstance, sourceMarker);
      });

      sourceMarker.addListener("mouseout", () => {
        sourceInfoWindow.close();
      });

      // Destination Marker
      const destinationMarker = new window.google.maps.Marker({
        position: destination,
        map: mapInstance,
        title: destinationName,
        icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
      });

      const destinationInfoWindow = new window.google.maps.InfoWindow({
        content: `<div><strong>Destination: ${destinationName}</strong></div>`,
      });

      destinationMarker.addListener("mouseover", () => {
        destinationInfoWindow.open(mapInstance, destinationMarker);
      });

      destinationMarker.addListener("mouseout", () => {
        destinationInfoWindow.close();
      });

      // Truck Marker with custom image
      const truckMarkerInstance = new window.google.maps.Marker({
        position: {
          lat: container.tracking.TruckLatitude,
          lng: container.tracking.TruckLongitude,
        },
        map: mapInstance,
        icon: {
          url: maptruck,
          scaledSize: new window.google.maps.Size(15, 40), // Adjust size as needed
          anchor: new window.google.maps.Point(20, 20), // Center the image
        },
      });
      setTruckMarker(truckMarkerInstance);

      const truckInfoWindow = new window.google.maps.InfoWindow({
        content: `<div><strong>Truck Number: ${container.truckNumber}</strong><br><strong>Container Number: ${container.containerNumber}</strong></div>`,
      });

      truckMarkerInstance.addListener("click", () => {
        if (truckInfoWindowOpen) {
          truckInfoWindow.close();
        } else {
          truckInfoWindow.open(mapInstance, truckMarkerInstance);
        }
        setTruckInfoWindowOpen(!truckInfoWindowOpen);
      });

      if (truckInfoWindowOpen) {
        truckInfoWindow.open(mapInstance, truckMarkerInstance);
      }

      // Path rendering and truck animation
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        map: mapInstance,
        suppressMarkers: true,
      });

      directionsService.route(
        {
          origin: source,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === "OK" && result && result.routes.length > 0) {
            directionsRenderer.setDirections(result);
            const route = result.routes[0].overview_path;
            animateTruck(route);
          } else {
            console.error(`Directions request failed due to ${status}`);
          }
        }
      );

      // Function to animate the truck and rotate based on path direction
      const animateTruck = (route: google.maps.LatLng[]) => {
        let step = 0;
        const interval = setInterval(() => {
          if (step < route.length - 1) {
            const position = route[step];
            const nextPosition = route[step + 1];

            // Calculate bearing between current and next position
            const bearing = calculateBearing(position, nextPosition);

            // Move truck marker and rotate
            setTruckPosition({ lat: position.lat(), lng: position.lng() });
            truckMarkerInstance.setPosition(position);
            truckMarkerInstance.setIcon({
              url: maptruck,
              scaledSize: new window.google.maps.Size(15, 40),
              anchor: new window.google.maps.Point(20, 20),
              rotation: bearing, // Rotate based on bearing
            });

            step++;
          } else {
            clearInterval(interval);
          }
        }, 2000); // Adjust animation speed as needed
      };
    };

    const calculateBearing = (
      pointA: google.maps.LatLng,
      pointB: google.maps.LatLng
    ) => {
      const lat1 = (pointA.lat() * Math.PI) / 180;
      const lon1 = (pointA.lng() * Math.PI) / 180;
      const lat2 = (pointB.lat() * Math.PI) / 180;
      const lon2 = (pointB.lng() * Math.PI) / 180;

      const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
      const x =
        Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
      const bearing = (Math.atan2(y, x) * 180) / Math.PI;
      return (bearing + 360) % 360; // Normalize to 0-360 degrees
    };

    if (container) {
      initializeMap();
    }
  }, [container, truckInfoWindowOpen]);

  return <div ref={mapRef} className="w-full h-screen" />;
};

export default MapComponent;
