import React from "react";

import typeIcon from "../../assets/type.png";
import truckIcon from "../../assets/truck.png";
import requiredTripIcon from "../../assets/requiredtrip.png";
import completedTripIcon from "../../assets/completedtrip.png";

interface TripInfo {
  AssignedTruck: number;
  RequiredTrip: number;
  CompletedTrip: number;
}

interface Transporter {
  id: string;
  transporter: string;
  "40HQ": TripInfo;
  "20OT": TripInfo;
  "40FL": TripInfo;
}

interface CompleteTransporterDetailProps {
  selectedTransporterIds: number[];
}

const transporterData: Transporter[] = [
  {
    id: "1",
    transporter: "Narendra Transporter",
    "40HQ": { AssignedTruck: 22, RequiredTrip: 140, CompletedTrip: 4 },
    "20OT": { AssignedTruck: 47, RequiredTrip: 22, CompletedTrip: 4 },
    "40FL": { AssignedTruck: 63, RequiredTrip: 22, CompletedTrip: 4 },
  },
  {
    id: "2",
    transporter: "Ajay Transport Co.",
    "40HQ": { AssignedTruck: 25, RequiredTrip: 150, CompletedTrip: 6 },
    "20OT": { AssignedTruck: 30, RequiredTrip: 120, CompletedTrip: 5 },
    "40FL": { AssignedTruck: 18, RequiredTrip: 160, CompletedTrip: 7 },
  },
  {
    id: "3",
    transporter: "Vijay Logistics",
    "40HQ": { AssignedTruck: 20, RequiredTrip: 130, CompletedTrip: 3 },
    "20OT": { AssignedTruck: 35, RequiredTrip: 125, CompletedTrip: 4 },
    "40FL": { AssignedTruck: 15, RequiredTrip: 150, CompletedTrip: 6 },
  },
  {
    id: "4",
    transporter: "Suresh Freight",
    "40HQ": { AssignedTruck: 23, RequiredTrip: 145, CompletedTrip: 5 },
    "20OT": { AssignedTruck: 32, RequiredTrip: 115, CompletedTrip: 4 },
    "40FL": { AssignedTruck: 14, RequiredTrip: 140, CompletedTrip: 5 },
  },
  {
    id: "5",
    transporter: "Ramesh Haulers",
    "40HQ": { AssignedTruck: 24, RequiredTrip: 155, CompletedTrip: 6 },
    "20OT": { AssignedTruck: 31, RequiredTrip: 135, CompletedTrip: 5 },
    "40FL": { AssignedTruck: 17, RequiredTrip: 165, CompletedTrip: 7 },
  },
  {
    id: "6",
    transporter: "Santosh Transport",
    "40HQ": { AssignedTruck: 21, RequiredTrip: 135, CompletedTrip: 4 },
    "20OT": { AssignedTruck: 34, RequiredTrip: 140, CompletedTrip: 6 },
    "40FL": { AssignedTruck: 19, RequiredTrip: 170, CompletedTrip: 8 },
  },
  {
    id: "7",
    transporter: "Mahesh Movers",
    "40HQ": { AssignedTruck: 26, RequiredTrip: 160, CompletedTrip: 7 },
    "20OT": { AssignedTruck: 28, RequiredTrip: 110, CompletedTrip: 4 },
    "40FL": { AssignedTruck: 20, RequiredTrip: 180, CompletedTrip: 9 },
  },
  {
    id: "8",
    transporter: "Rajesh Logistics",
    "40HQ": { AssignedTruck: 27, RequiredTrip: 165, CompletedTrip: 8 },
    "20OT": { AssignedTruck: 29, RequiredTrip: 125, CompletedTrip: 5 },
    "40FL": { AssignedTruck: 21, RequiredTrip: 190, CompletedTrip: 10 },
  },
  {
    id: "9",
    transporter: "Praveen Carriers",
    "40HQ": { AssignedTruck: 28, RequiredTrip: 170, CompletedTrip: 9 },
    "20OT": { AssignedTruck: 27, RequiredTrip: 105, CompletedTrip: 4 },
    "40FL": { AssignedTruck: 22, RequiredTrip: 200, CompletedTrip: 11 },
  },
  {
    id: "10",
    transporter: "Sanjay Transport Service",
    "40HQ": { AssignedTruck: 29, RequiredTrip: 175, CompletedTrip: 10 },
    "20OT": { AssignedTruck: 26, RequiredTrip: 100, CompletedTrip: 3 },
    "40FL": { AssignedTruck: 23, RequiredTrip: 210, CompletedTrip: 12 },
  },
];

const iconStyle = {
  width: "20px",
  height: "20px",
  marginRight: "8px",
};

const CompleteTransporterDetail: React.FC<CompleteTransporterDetailProps> = ({ selectedTransporterIds }) => {
  const selectedTransporters = transporterData.filter((transporter) =>
    selectedTransporterIds.includes(parseInt(transporter.id))
  );

  return (
    <div className="space-y-4  ">
      {selectedTransporters.map((transporter) => (
        <div
          key={transporter.id}
          className="bg-gray-100 p-2 rounded-lg shadow-md "
        >
          <h2 className="text-xl font-bold text-transporterText mb-4 text-left border-b border-bottomline p-3">
            {transporter.transporter}
          </h2>
          <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            {(["40HQ", "20OT", "40FL"] as const).map((type) => (
              <div key={type} className="bg-white shadow-md rounded p-4 text-grayText">
              
                <div className="flex justify-between items-center p-1">
                <h3 className="  flex items-center ">
                  <img src={typeIcon} alt={`${type} Icon`} style={iconStyle} />
                  Type: 
                </h3>
                  <span className="text-transporterText font-semibold">{type}</span>
                </div>
                <div className="flex justify-between items-center p-1">
                  <span className="flex items-center ">
                    <img src={truckIcon} alt="Truck Icon" style={iconStyle} />
                    Assigned Truck:
                  </span>
                  <span className="text-transporterText font-semibold">{transporter[type].AssignedTruck}</span>
                </div>
                <div className="flex justify-between items-center p-1">
                  <span className="flex items-center">
                    <img src={requiredTripIcon} alt="Required Trip Icon" style={iconStyle} />
                    Required Trip:
                  </span>
                  <span className="text-transporterText font-semibold">{transporter[type].RequiredTrip}</span>
                </div>
                <div className="flex justify-between items-center p-1">
                  <span className="flex items-center">
                    <img src={completedTripIcon} alt="Completed Trip Icon" style={iconStyle} />
                    Completed Trip:
                  </span>
                  <span className="text-green-600 font-semibold">{transporter[type].CompletedTrip}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompleteTransporterDetail;
