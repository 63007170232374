import React, { useEffect, useState } from "react";
import { loadGoogleMapsApi } from "../../utils/loadgoogleMapsApi";
import sourceMarkerImage from '../../assets/logo.png'; 
import destinationMarkerImage from '../../assets/logo.png';

interface CompleteJobDetailProps {
  job: any;
}

const containerStyle = {
  width: "100%",
  height: "500px",
};

const CompleteJobDetail: React.FC<CompleteJobDetailProps> = ({ job }) => {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [distance, setDistance] = useState<string>("");
  const [estimatedTime, setEstimatedTime] = useState<string>("");

  useEffect(() => {
    loadGoogleMapsApi("AIzaSyCEZohML94bY36-YMhs6RAZsiJArcvjyaA")
      .then(() => setGoogleMapsLoaded(true))
      .catch((err) => console.error("Error loading Google Maps API", err));
  }, []);

  useEffect(() => {
    if (googleMapsLoaded && job) {
      const mapOptions: google.maps.MapOptions = {
        zoom: 8,
        center: {
          lat: parseFloat(job.sourceLatitude),
          lng: parseFloat(job.sourceLongitude),
        },
      };
      const mapInstance = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        mapOptions
      );
      setMap(mapInstance);

      const sourcePosition = {
        lat: parseFloat(job.sourceLatitude),
        lng: parseFloat(job.sourceLongitude),
      };
      const destinationPosition = {
        lat: parseFloat(job.destinationLatitude),
        lng: parseFloat(job.destinationLongitude),
      };

      const createMarker = (position: google.maps.LatLngLiteral, label: string, imageUrl?: string) => {
        const markerOptions: google.maps.MarkerOptions = {
          position,
          map: mapInstance,
          label,
        };
        if (imageUrl) {
          markerOptions.icon = {
            url: imageUrl,
            scaledSize: new google.maps.Size(30, 30), // Adjust size as needed
          };
        }
        new google.maps.Marker(markerOptions);
      };

      createMarker(sourcePosition, "S", sourceMarkerImage);
      createMarker(destinationPosition, "D", destinationMarkerImage);

      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();
      directionsRenderer.setMap(mapInstance);

      directionsService.route(
        {
          origin: sourcePosition,
          destination: destinationPosition,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result) {
            directionsRenderer.setDirections(result);

            // distance and estimated time from google api
            const leg = result.routes[0].legs[0];
            if (leg) {
              setDistance(leg.distance?.text || "N/A");
              setEstimatedTime(leg.duration?.text || "N/A");
            }
          } else {
            console.error(`Error fetching directions: ${status}`, result);
          }
        }
      );
    }
  }, [googleMapsLoaded, job]);

  const formatDate = (epochTime: number): string => {
    const date = new Date(epochTime);
    return date.toLocaleString();
  };

  if (!job) return <div>Select a job to see details</div>;

  return (
    <div className="p-4 bg-white rounded-lg shadow-md space-y-4 text-left ">
      <div className="grid grid-cols-2 gap-4">
        <p>
          <span className="font-medium text-grayText">From:</span> {job.sourceName}
        </p>
        <p>
          <span className="font-medium text-grayText">To:</span> {job.destinationName}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <p>
          <span className="font-medium text-grayText">Vessel Name:</span>{" "}
          {job.vesselDetails.vessel}
        </p>
        <p>
          <span className="font-medium text-grayText">Service:</span>{" "}
          {job.vesselDetails.service}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <p>
          <span className="font-medium text-grayText">VOA:</span> {job.vesselDetails.voa}
        </p>
        <p>
          <span className="font-medium text-grayText">Voy/Via:</span>{" "}
          {job.vesselDetails.voyvia}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <p>
          <span className="font-medium text-grayText">Gate Open Date Time:</span>{" "}
          {formatDate(job.vesselDetails.gateopendatetime)}
        </p>
        <p>
          <span className="font-medium text-grayText">Cutoff Date Time:</span>{" "}
          {formatDate(job.vesselDetails.cutoffdatetime)}
        </p>
      </div>
      <div className="flex mt-4 items-center space-x-2">
        <p className="font-medium text-grayText">Container:</p>
        <div className="flex space-x-8">
          {job.vesselDetails.inventoryDetails.map((row: any) => (
            <div key={row.id} className="text-center">
              <span>{row.count} x {row.size} {row.type}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-smallCardBackground text-smallcardtext font-bold grid grid-cols-2 gap-4 mt-4 p-5 rounded-xl">
        <p>
          <span className="font-bold text-smallcardtext">Distance:</span> {distance}
        </p>
        <p>
          <span className="font-bold text-smallcardtext">Estimated Time:</span> {estimatedTime}
        </p>
      </div>
      <div id="map" className="rounded-lg overflow-hidden" style={containerStyle}></div>
    </div>
  );
};

export default CompleteJobDetail;
