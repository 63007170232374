import { clarity } from "clarity-js";

const initializeClarity = () => {
  const clarityProjectId = process.env.REACT_APP_CLARITY_PROJECT_ID;

  if (clarityProjectId) {
    clarity.start({ projectId: clarityProjectId });
    console.log(`Clarity initialized with Project ID: ${clarityProjectId}`);
  } else {
    console.error("Clarity Project ID is not defined!");
  }
};

export default initializeClarity;
