import React, { useState } from "react";
import "./ProfilePage.scss";
import { ReactComponent as Avatar } from "../../assets/images/avatar.svg";
import backgroundimage from "../../assets/images/profile-bkg.jpeg";
import EditProfileModal from "./EditProfileModal";
import EditCompanyModal from "./EditCompanyModal";
import NotificationPanel from "../Notification/NotificationPanel";

const ProfilePage: React.FC = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isContainerModalOpen, setIsContainerModalOpen] = useState(false);

  const profileInfo = {
    fullName: "Farhan",
    role: "Admin",
    email: "farhan@cnbtek.com",
    phone: "7506745272",
    password: "password",
  };

  const companyinfo = {
    companyName: "Meraki Express",
    gstNo: "29ABCDE1234F1Z5",
    address: "3, MIDDLE ROAD, HASTINGS, KOLKATA Kolkata WB 700022 IN",
  };

  return (
    <div className="profile-page">
      {/* <NotificationPanel /> */}
      <div className="profile-card">
        <div className="card-top">
          <img
            src={backgroundimage}
            alt="Background"
            className="background-image"
          />
          <div className="avatar">
            <Avatar className="avatar-image" />
          </div>
        </div>

        <div className="containerbox">
          {/* Personal Information Section */}
          <div className="container personal-info">
            <div className="header-row">
              <h2>Personal Information</h2>
              {/* <button
                className="edit-button"
                onClick={() => setIsProfileModalOpen(true)}
              >
                Edit
              </button> */}
            </div>
            <div className="flex-row">
              <div className="flex-col half-width">
                <div className="label">Full Name:</div>
                <div className="value">
                  {/* {profileInfo.fullName} */}
                  Meraki Express
                </div>
              </div>
              <div className="flex-col half-width">
                <div className="label">Role:</div>
                <div className="value">
                  Super Admin
                  {/* {profileInfo.role} */}
                </div>
              </div>
            </div>
            <div className="flex-row">
              <div className="flex-col third-width">
                <div className="label">Email:</div>
                <div className="value">{profileInfo.email}</div>
              </div>
              <div className="flex-col third-width">
                <div className="label">Phone:</div>
                <div className="value">+91 {profileInfo.phone}</div>
              </div>
              <div className="flex-col third-width">
                <div className="label">Password:</div>
                <div className="value">
                  {"*".repeat(profileInfo.password.length)}
                </div>
              </div>
            </div>
          </div>

          {/* Container Information Section */}
          <div className="container container-info">
            <div className="header-row">
              <h2>Container Information</h2>
              {/* <button
                className="edit-button"
                onClick={() => setIsContainerModalOpen(true)}
              >
                Edit
              </button> */}
            </div>
            <div className="flex-row">
              <div className="flex-col half-width">
                <div className="label">Company Name:</div>
                <div className="value">{companyinfo.companyName}</div>
              </div>
              <div className="flex-col half-width">
                <div className="label">GST No.:</div>
                <div className="value">{companyinfo.gstNo}</div>
              </div>
            </div>
            <div className="flex-row">
              <div className="flex-col third-width">
                <div className="label">Address:</div>
                <div className="value">{companyinfo.address}</div>
              </div>
            </div>
          </div>

          {/* Modals for Editing */}
          {/* <EditProfileModal
            isOpen={isProfileModalOpen}
            onClose={() => setIsProfileModalOpen(false)}
            initialData={profileInfo}
          />

          <EditCompanyModal
            isOpen={isContainerModalOpen}
            onClose={() => setIsContainerModalOpen(false)}
            initialData={companyinfo}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
