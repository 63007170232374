import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileButton from "../ProfileButton/ProfileButton";
import { getPageTitle, getSubPageTitle } from "../../utils/dynamicPageHeader";
import SegmentButton from "../SegmentButton/SegmentButton";
import { ReactComponent as Backbutton } from "../../assets/icons/back-button.svg";
import "./Header.scss";

interface HeaderProps {
  currentSegment: string;
  onSegmentChange: (segment: string) => void;
}

const Header: React.FC<HeaderProps> = ({ currentSegment, onSegmentChange }) => {
  const location = useLocation();
  const currentPage = location.pathname.substring(0);
  const pageTitle = getPageTitle(currentPage);
  const showSegmentButtons = ["/jobs"].includes(currentPage);
  const [activeSegment, setActiveSegment] = useState(0);
  const navigate = useNavigate();
  const subHeaderPathNames = ["/assign-containers"];
  const showSubPageHeader = subHeaderPathNames.some(
    (subHeaderPathName: string) => currentPage.includes(subHeaderPathName)
  );
  const subPagetitle = getSubPageTitle(currentPage);

  useEffect(() => {
    // console.log("Header received currentSegment:", currentSegment);
    setActiveSegment(currentSegment === "Existing" ? 1 : 0);
  }, [currentSegment]);

  const handleSegmentChange = (segment: string) => {
    onSegmentChange(segment);
  };

  return (
    <div className="page-header ">
      <div className="left-section flex items-center">
        {/* <div className="header-name px-[10px]">{pageTitle}</div> */}
        {!showSubPageHeader && (
          <div className="header-name px-[10px]">{pageTitle}</div>
        )}
        {showSubPageHeader && (
          <>
            <Backbutton
              className="back-button cursor-pointer text-[#A3A8B4]"
              onClick={() => navigate(-1)}
            ></Backbutton>
            <div className="header-name px-[10px]">{subPagetitle}</div>
          </>
        )}
      </div>

      {showSegmentButtons && (
        <div className="center-section">
          <SegmentButton
            segments={["New", "Existing"]}
            onSegmentChange={handleSegmentChange}
            currentSegment={currentSegment}
          />
        </div>
      )}

      <div className="right-section flex items-center">
        <ProfileButton></ProfileButton>
      </div>
    </div>
  );
};

export default Header;
