import React, { useState } from "react";
import MapComponent from "./MapComponent";
import truck from "../../assets/truck.png";
import greenCircle from "../../assets/greencircle.png"; // Placeholder for green circle image

const TrackingComponent = ({ selectedJob }: any) => {
  const [selectedContainer, setSelectedContainer] = useState<any>(null);

  const handleContainerSelect = (container: any) => {
    setSelectedContainer(container);
  };

  const inTransitContainers = selectedJob?.containerDetails.filter(
    (container: any) => container.status === "In Transit"
  );

  return (
    <div className="relative h-screen">
      {/* Container List */}
      <div className="absolute top-2 left-2 w-1/4 h-full z-20 bg-card py-6 overflow-y-auto rounded-xl">
        {/* Job Header and Job Details */}
        <div className="flex justify-between mb-4 border-b border-gray-300 px-4 pb-2">
          <h2 className="text-xl font-bold">#{selectedJob.jobId}</h2>
          <span className="text-yellow-400 font-bold">
            {selectedJob.jobStatus}
          </span>
        </div>

        {/* Source and Destination Info */}
        <div className="mb-6 border-b border-gray-300 px-4 pb-2 font-bold text-transporterText">
          <div className="flex items-center mb-2">
            <span className="w-3 h-3 bg-gray-500 rounded-full mr-2"></span>
            <span>{selectedJob.sourceName}</span>
          </div>
          <div className="flex items-center">
            <span className="w-3 h-3 bg-blueText rounded-full mr-2"></span>
            <span>{selectedJob.destinationName}</span>
          </div>
        </div>

        {/* Job Status Component */}
        <div className="px-4 mb-4">
          <div className="text-left text-grayText font-bold mb-1">
            Job Status
          </div>
          <div className="flex justify-between items-center text-sm mb-2">
            <span className="text-green-500">57%</span>
            <span>
              <span className="text-green-500 font-bold">200</span>/
              <span>350</span>
            </span>
          </div>
          {/* Progress Bar */}
          <div className="w-full bg-gray-200 h-3 rounded-full">
            <div
              className="bg-green-500 h-3 rounded-full"
              style={{ width: "57%" }}
            ></div>
          </div>
        </div>

        {/* Container List */}
        <div className="overflow-y-auto h-2/3 text-left px-4">
          {inTransitContainers.map((container: any, index: number) => (
            <div
              key={index}
              className={`border rounded-lg mb-4 py-4 ${
                selectedContainer === container
                  ? "bg-selectedCard border-blueText shadow-lg"
                  : "border-grayText"
              }`}
              onClick={() => handleContainerSelect(container)}
            >
              <div className="flex justify-between items-center mb-2 border-b border-grayText p-2">
                <div className="flex items-center font-bold">
                  {/* Truck image */}
                  <img src={truck} alt="Truck" className="mr-2" />
                  <span>{container.truckNumber}</span>
                </div>

                {/* Button with green circle when selected */}
                <button
                  className={`font-bold px-2 py-1 rounded flex items-center ${
                    selectedContainer === container
                      ? "text-green-500"
                      : "text-blueText underline"
                  }`}
                >
                  {selectedContainer === container && (
                    <img
                      src={greenCircle}
                      alt="Selected"
                      className="w-4 h-4 mr-1"
                    />
                  )}
                  {selectedContainer === container ? "Tracking" : "Track"}
                </button>
              </div>

              {/* Grid Layout for Container Details */}
              <div className="grid grid-cols-2 gap-2 p-2 font-bold text-transporterText">
                <div>
                  <span className="text-grayText font-semibold">
                    Container No
                  </span>
                </div>
                <div>
                  <span>{container.containerNumber}</span>
                </div>

                <div>
                  <span className="text-grayText font-semibold">
                    Size & Type
                  </span>
                </div>
                <div>
                  <span>
                    {container.size} {container.type}
                  </span>
                </div>

                <div>
                  <span className="text-grayText font-semibold">ETA</span>
                </div>
                <div>
                  <span>{container.tracking.ETA}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Map Section */}
      <div className="w-full h-screen">
        {selectedContainer && (
          <MapComponent
            container={selectedContainer}
            sourceName={selectedJob.sourceName}
            destinationName={selectedJob.destinationName}
            source={{
              lat: selectedJob.sourceLatitude,
              lng: selectedJob.sourceLongitude,
            }}
            destination={{
              lat: selectedJob.destinationLatitude,
              lng: selectedJob.destinationLongitude,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TrackingComponent;
