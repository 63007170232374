import React, { useState } from "react";
import "./JobCard.scss";
import { ReactComponent as CicleIcon } from "../../assets/icons/circle.svg";
import { ReactComponent as JobStatusIcon } from "../../assets/icons/job-status-icon.svg";
import { Job, JobOrder } from "../../interfaces/job.interface";
import {
  calculateCycleTime,
  dateFromNow,
  formatIsoDate,
  isDateLessThanOneMinute,
} from "../../utils/general";
import JobStatus from "./JobStatus";

interface JobCardProps {
  CardData: JobOrder[];
  selectedJobId?: string | null;
  onSelect: (job: JobOrder) => void;
}

const JobCard: React.FC<JobCardProps> = ({
  CardData,
  selectedJobId,
  onSelect,
}) => {
  const [filter, setFilter] = useState<string>("All");
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleFilterChange = (status: string) => {
    setFilter(status);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const isWithinDateRange = (date: number) => {
    if (!startDate && !endDate) return true;
    const jobDate = new Date(date).getTime();
    const start = startDate ? new Date(startDate).getTime() : null;
    const end = endDate ? new Date(endDate).getTime() : null;
    if (start && end) {
      return jobDate >= start && jobDate <= end;
    } else if (start) {
      return jobDate >= start;
    } else if (end) {
      return jobDate <= end;
    }
    return true;
  };

  // const filteredCardData = CardData.filter((job) => {
  //   const matchesFilter = filter === "All" || job.jobStatus === filter;
  //   const matchesSearchQuery = searchQuery
  //     ? job.jobId.toString().includes(searchQuery) ||
  //       job.sourceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       job.destinationName.toLowerCase().includes(searchQuery.toLowerCase())
  //     : true;
  //   const matchesDateRange = isWithinDateRange(
  //     job.vesselDetails.gateopendatetime
  //   );
  //   return matchesFilter && matchesSearchQuery && matchesDateRange;
  // });

  // const statusCounts = CardData.reduce((counts, job) => {
  //   counts[job.jobStatus] = (counts[job.jobStatus] || 0) + 1;
  //   return counts;
  // }, {});

  return (
    <div className="jc">
      {/* <div className="mb-4 flex flex-wrap justify-between items-center">
        <div className="flex items-center flex-wrap gap-[20px]">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="primary_button transition duration-200"
          >
            {showFilters ? "Hide Filters" : "Show Filters"}
          </button>
          <input
            type="text"
            placeholder="Search by Job ID, Source, Destination"
            value={searchQuery}
            onChange={handleSearchChange}
            className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 text-sm"
          />
        </div>
      </div>
      {showFilters && (
        <div className="font-bold mb-4 flex flex-wrap ">
          {["All", "In Progress", "Draft", "Completed", "Cancelled"].map(
            (status) => (
              <button
                key={status}
                onClick={() => handleFilterChange(status)}
                className={`flex  items-center justify-between px-4 py-2 m-2 rounded-md ${
                  filter === status
                    ? "bg-mainButtonBackground text-white"
                    : "bg-card text-grayText"
                } hover:bg-mainButtonBackground hover:text-filtercountactive`}
              >
                <span>{status}</span>
                <div
                  className={`ml-2 w-8 h-8 flex items-center justify-center rounded-md ${
                    filter === status
                      ? "bg-filtercountactive"
                      : "bg-filtercount "
                  }`}
                >
                  {status === "All"
                    ? CardData.length
                    : statusCounts[status] || 0}
                </div>
              </button>
            )
          )}
          <div className="flex items-center flex-wrap  mt-2 text-grayText">
            <label className="text-sm">From:</label>
            <input
              type="date"
              placeholder="From"
              value={startDate}
              onChange={handleStartDateChange}
              className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 text-sm"
            />
            <label className="text-sm">To:</label>
            <input
              type="date"
              placeholder="To"
              value={endDate}
              onChange={handleEndDateChange}
              className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 text-sm"
            />
          </div>
        </div>
      )} */}
      <div className="flex flex-wrap jobs_list">
        {CardData.map((job) => (
          <div
            key={job.jobCode}
            className={`job job_card_cover card-shadow   ${
              selectedJobId === job.jobCode ? "selected_job_card" : ""
            }`}
            onClick={() => onSelect(job)}
          >
            <div className="card_content">
              <div className="card_header">
                <div className="left job_id">#{job.jobCode}</div>
                {job.jobStatus === "INITIATED" &&
                  isDateLessThanOneMinute(job.createdDate) && (
                    <div className="new_job_label">New</div>
                  )}
                {!isDateLessThanOneMinute(job.createdDate) && (
                  <div className={`job_status ${job.jobStatus}`}>
                    <JobStatus jobStatus={job.jobStatus} />
                  </div>
                )}
              </div>
              <div className="card_body">
                <div className="src-dest">
                  <div className="src">
                    <CicleIcon className="source_icon" />
                    <div
                      className="source_name truncate-two-lines"
                      title={job.source}
                    >
                      {job.source}
                    </div>
                  </div>
                  <div className="route"></div>
                  <div className="dest">
                    <CicleIcon className="destination_icon" />
                    <div
                      className="destination_name truncate-two-lines"
                      title={job.destination}
                    >
                      {job.destination}
                    </div>
                  </div>
                </div>
                <div className="card_footer">
                  <div className="left">
                    <div className="label">Created</div>
                    <div className="value">
                      {dateFromNow(job.createdDate)}
                      {/* {formatIsoDate(job.createdDate)} */}
                    </div>
                  </div>

                  <div className="right">
                    <div className="label">Cycle Time</div>
                    <div className="value">
                      {calculateCycleTime(job.gateOpenDate, job.gateCloseDate)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobCard;
