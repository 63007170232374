import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";

import LeftSideNavbar from "../LeftSideNavbar";
import MassEditModal from "../MassEditModal";

import deletelogo from "../../assets/deletelogo.png";
import emptylist from "../../assets/emptylist.png";

import TrackingComponent from "../Tracking/TrackingComponent";

interface CompleteContainerProps {
  selectedJob: any;
 
}

const CompleteContainer: React.FC<CompleteContainerProps> = ({ selectedJob, }) => {
  const { movementCycle, vesselDetails, containerDetails = [] } = selectedJob;

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isMassEditModalOpen, setIsMassEditModalOpen] = useState(false);
  const [isMassDeleteModalOpen, setIsMassDeleteModalOpen] = useState(false);
  const [newContainer, setNewContainer] = useState({
    containerNumber: "",
    size: "",
    type: "",
    truckNumber: "",
    transporter: "",
  });
  const [selectedContainers, setSelectedContainers] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState({
    priority: null as "asc" | "desc" | null,
    dateTime: null as "asc" | "desc" | null,
  });

  const [containers, setContainers] = useState(containerDetails);
  const [isTrackingVisible, setTrackingVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);


  const handleTrackingClick = () => {
    setTrackingVisible(true);
  };




  useEffect(() => {
    setContainers(containerDetails);
  }, [containerDetails]);

  const handleSort = (key: "priority" | "dateTime") => {
    const order = sortOrder[key] === "asc" ? "desc" : "asc";
    setSortOrder({
      ...sortOrder,
      [key]: order,
      ...(key === "priority" ? { dateTime: null } : {}),
      ...(key === "dateTime" ? { priority: null } : {}),
    });
  };

  useEffect(() => {
    console.log("Sort order updated:", sortOrder);
  }, [sortOrder]);

  const priorityOrder = { Low: 1, Medium: 2, High: 3 };
  const comparePriority = (a: any, b: any) => {
    const order =
      priorityOrder[a.priority as "Low" | "Medium" | "High"] -
      priorityOrder[b.priority as "Low" | "Medium" | "High"];
    return sortOrder.priority === "asc" ? order : -order;
  };

  const compareDateTime = (a: any, b: any) => {
    const order =
      new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime();
    return sortOrder.dateTime === "asc" ? order : -order;
  };

  const handlePriorityChange = (index: number, newPriority: string) => {
    const updatedContainers = containers.map((container: any, i: number) =>
      i === index ? { ...container, priority: newPriority } : container
    );
    setContainers(updatedContainers);
   
    selectedJob.containerDetails = updatedContainers;
  };

  useEffect(() => {
    // This effect runs when selectedJob.containerDetails changes
    
    
    console.log('Container details updated:', selectedJob.containerDetails);
   
    // for api space is here
  }, [selectedJob.containerDetails]);

  const handleAddContainer = () => {
    selectedJob.containerDetails = [...containerDetails, newContainer];
    setIsAddModalOpen(false);
    setNewContainer({
      containerNumber: "",
      size: "",
      type: "",
      truckNumber: "",
      transporter: "",
    });
  };

  const handleSelectContainer = (container: any) => {
    setSelectedContainers((prevSelected) =>
      prevSelected.find((c) => c.containerNumber === container.containerNumber)
        ? prevSelected.filter(
            (c) => c.containerNumber !== container.containerNumber
          )
        : [...prevSelected, container]
    );
  };

  const handleSelectAll = () => {
    if (selectedContainers.length === containerDetails.length) {
      setSelectedContainers([]);
    } else {
      setSelectedContainers(containerDetails);
    }
  };

  const handleMassDelete = () => {
    const updatedContainers = containerDetails.filter(
      (container: any) =>
        !selectedContainers.some(
          (selected) => selected.containerNumber === container.containerNumber
        )
    );
    selectedJob.containerDetails = updatedContainers;
    setSelectedContainers([]);
    setIsMassDeleteModalOpen(false);
  };

  // const handleMassEdit = () => {
  //   const updatedContainers = containerDetails.map((container: any) =>
  //     selectedContainers.find(selected => selected.containerNumber === container.containerNumber)
  //       ? { ...container, ...newContainer }
  //       : container
  //   );
  //   selectedJob.containerDetails = updatedContainers;
  //   setIsMassEditModalOpen(false);
  //   setSelectedContainers([]);
  // };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileType = file.name.split(".").pop()?.toLowerCase();

      if (fileType === "csv") {
        Papa.parse(file, {
          header: true,
          complete: (results) => {
            const parsedData = results.data as Array<any>;

            const containers = parsedData.map((row: any) => ({
              containerNumber: row["Container Number"] || "",
              size: row["Size"] || "",
              type: row["Type"] || "",
              // truckNumber: row["Truck Number"] || "",
              // transporter: row["Transporter"] || "",
              priority: "Low",
            }));

            selectedJob.containerDetails = [...containerDetails, ...containers];
            setSelectedContainers([]);
            setSearchTerm("");
          },
        });
      } else if (fileType === "xls" || fileType === "xlsx") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

          const headers = jsonData[0] as string[];
          const rows = jsonData.slice(1);

          const containers = rows.map((row: any) => {
            const rowData = headers.reduce((acc: any, header, index) => {
              acc[header] = row[index];
              return acc;
            }, {});

            return {
              containerNumber: rowData["Container Number"] || "",
              size: rowData["Size"] || "",
              type: rowData["Type"] || "",
              // truckNumber: rowData["Truck Number"] || "",
              // transporter: rowData["Transporter"] || "",
              // status: rowData["Status"] || "",
              priority: "Low",
            };
          });

          // Updating data
          selectedJob.containerDetails = [...containerDetails, ...containers];
          setSelectedContainers([]);
          setSearchTerm("");
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const renderTableContent = () => {
    if (!containerDetails || containerDetails.length === 0) {
      return (
        <div className="text-center text-grayText font-bold">
          <img src={emptylist} alt="Empty List" className="mx-auto" />
          <p>
            Please provide the container details so they can be shared with the
            relevant{" "}
          </p>
          <p>transporters and drivers.</p>
          <div className="mt-4">
            <button
              className="font-bold bg-white text-transporterText px-4 py-2 rounded mr-2 border border-transporterText"
              onClick={() => document.getElementById("fileInput")?.click()}
            >
              Upload Containers
            </button>
            <input
              type="file"
              id="fileInput"
              className="hidden"
              accept=".csv, .xls, .xlsx"
              onChange={handleFileUpload}
            />
            <button
              className="bg-mainButtonBackground text-mainButtonText px-4 py-2 rounded"
              onClick={() => setIsAddModalOpen(true)}
            >
              Add Container
            </button>
          </div>
        </div>
      );
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    };




    const filteredContainers = containerDetails.filter((container: any) =>
      (container.containerNumber
        ? container.containerNumber.toString().toLowerCase()
        : ""
      ).includes(searchTerm.toLowerCase())
    );

    //use ContainerDetails - ...containerDetails if error
    const sortedContainers = [...filteredContainers].sort((a, b) => {
      let result = 0;
      if (sortOrder.priority) {
        result = comparePriority(a, b);
      }
      if (result === 0 && sortOrder.dateTime) {
        result = compareDateTime(a, b);
      }
      return result;
    });

    const convertEpochToDateTime = (epochTime: number) => {
      const date = new Date(epochTime);
      return date.toLocaleString();
    };

    return (
      <div>
        

       
    
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-background text-grayText">
              <th className="px-4 py-2 text-center">
                <input
                  type="checkbox"
                  checked={
                    selectedContainers.length === filteredContainers.length
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th className="px-4 py-2">SI.No</th>
              <th className="px-4 py-2">Container Number</th>
              <th className="px-4 py-2">Size and Type</th>
              <th
                className="px-4 py-2 cursor-pointer"
                onClick={() => handleSort("priority")}
              >
                Priority {sortOrder.priority && (sortOrder.priority === "asc" ? "↑" : "↓")}
              </th>
              <th className="px-4 py-2">Truck</th>
              <th className="px-4 py-2">Transporter</th>
              <th
                className="px-4 py-2 cursor-pointer"
                onClick={() => handleSort("dateTime")}
              >
                Date and Time {sortOrder.dateTime && (sortOrder.dateTime === "asc" ? "↑" : "↓")}
              </th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">View Map</th>
            </tr>
          </thead>
          <tbody>
            {sortedContainers.map((container: any, index: number) => (
              <tr
                key={container.containerNumber || index}
                className={`${
                  selectedContainers.some(
                    (c) => c.containerNumber === container.containerNumber
                  )
                    ? "bg-containerselected "
                    : "bg-white text-notSelected hover:bg-gray-50"
                }`}
              >
                <td className="border px-4 py-2 text-center ">
                  <input
                    type="checkbox"
                    checked={selectedContainers.some(
                      (c) => c.containerNumber === container.containerNumber
                    )}
                    onChange={() => handleSelectContainer(container)}
                  />
                </td>
                <td className="border px-4 py-2 text-center">{index + 1}</td>
                <td className="border px-4 py-2">
                  {container.containerNumber || "N/A"}
                </td>
                <td className="border px-4 py-2">{`${container.size} - ${container.type}`}</td>
                <td className="border px-4 py-2">
                  <select
                    value={container.priority || "N/A"}
                    onChange={(e) =>
                      handlePriorityChange(index, e.target.value)
                    }
                    className=" "
                  >
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                  </select>
                </td>
                <td className="border px-4 py-2">
                  {container.truckNumber || "N/A"}
                </td>
                <td className="border px-4 py-2">
                  {container.transporter || "N/A"}
                </td>
                <td className="border px-4 py-2">
                  {convertEpochToDateTime(container.dateTime) || "N/A"}
                </td>
                <td className="border px-4 py-2">
                  {container.status || "N/A"}
                </td>
                <td className="border px-4 py-2 text-center">
                  <button className="bg-gray-400 text-white px-2 py-1 rounded"
                  onClick={handleTrackingClick}
                  >
                    ...
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
              {isTrackingVisible && <TrackingComponent  />}

      </div>
    );
  };
  

 

 
  const renderModalContent = () => {
    
    const jobIdDisplay = (
     
      <p className="text-xl font-semibold mb-2">Job ID: {selectedJob.jobId} <LeftSideNavbar/></p>
      
    );

    switch (movementCycle) {
      case "Export":
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">
              Export Details {jobIdDisplay}
              
            </h2>

            {renderTableContent()}
          </div>
        );
      case "Import":
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">
              Import Details {jobIdDisplay}
            </h2>

            {renderTableContent()}
          </div>
        );
      case "General":
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">
              General Details {jobIdDisplay}
            </h2>

            {renderTableContent()}
          </div>
        );
      default:
        return (
          <p className="text-center">
            No details available for this movement cycle.
          </p>
        );
    }
  };

  return (
    <div className="">
      <div className="relative bg-background rounded-lg shadow-lg w-full h-full overflow-auto">
  

        <div className="p-6">{renderModalContent()}</div>
    
      </div>
    </div>
  );
};

export default CompleteContainer;
