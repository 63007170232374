import "./Transporter.scss";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import TransporterService from "../services/TransporterService";
import { Inventory } from "../interfaces/inventory.interface";
import { containerSizeToString } from "../utils/general";

interface TransporterItem {
  id: string;
  name: string;
}

interface TransporterProps {
  inventoryData: Inventory[];
}

const Transporter = forwardRef<
  { generateTransporterJobHandlingRequests: () => any; resetData: () => any },
  TransporterProps
>(({ inventoryData }, ref) => {
  const uniqueCombinations = Array.from(
    new Set(inventoryData.map((item) => `${item.size}-${item.type}`))
  );

  const [transporterData, setTransporterData] = useState<TransporterItem[]>([]);
  const [selectedTransporters, setSelectedTransporters] = useState<string[]>(
    []
  );
  const [inputValues, setInputValues] = useState<
    Record<string, Record<string, number>>
  >({});

  useEffect(() => {
    loadTransporters();
  }, []);

  const loadTransporters = async () => {
    try {
      const res = await TransporterService.getMyTransporters();
      setTransporterData(res.data.data);
    } catch (error) {
      console.log("Error loading transporters", error);
    }
  };

  const handleCheckboxChange = (transporterId: string) => {
    setSelectedTransporters((prev) =>
      prev.includes(transporterId)
        ? prev.filter((id) => id !== transporterId)
        : [...prev, transporterId]
    );
  };

  const handleInputChange = (
    transporterId: string,
    sizeType: string,
    value: string
  ) => {
    const count = value ? parseInt(value) : 0;

    const currentTotalFilledCount = selectedTransporters.reduce((sum, id) => {
      return sum + (inputValues[id]?.[sizeType] || 0);
    }, 0);

    const expectedCount =
      inventoryData.find((item) => `${item.size}-${item.type}` === sizeType)
        ?.count || 0;

    // Prevent total count from exceeding expected count
    if (
      currentTotalFilledCount -
        (inputValues[transporterId]?.[sizeType] || 0) +
        count >
      expectedCount
    ) {
      console.error(
        `Total count for ${sizeType} exceeds the expected count of ${expectedCount}.`
      );
      return;
    }

    setInputValues((prev) => ({
      ...prev,
      [transporterId]: {
        ...prev[transporterId],
        [sizeType]: count,
      },
    }));
  };

  const generateTransporterJobHandlingRequests = () => {
    const finalArray = selectedTransporters.map((transporterId) => {
      const countTypeSizeRequestList = uniqueCombinations
        .map((combo) => {
          const [size, type] = combo.split("-");
          const count = inputValues[transporterId]?.[combo] || 0;
          return count > 0
            ? {
                count: count,
                containerType: type,
                containerSize: containerSizeToString(parseInt(size)),
              }
            : null; // Return null if count is 0
        })
        .filter((item) => item !== null); // Remove null entries from array

      return {
        transporterId: transporterId,
        countTypeSizeRequestList: countTypeSizeRequestList,
      };
    });

    return finalArray.filter(
      (item) => item.countTypeSizeRequestList.length > 0
    );
  };

  const resetData = () => {
    setSelectedTransporters([]);
  };

  useImperativeHandle(ref, () => ({
    generateTransporterJobHandlingRequests,
    resetData,
  }));

  return (
    <div className="transporter-page bg-card rounded-xl">
      <div className="table-container">
        <table className=" bg-white rounded-xl">
          <thead>
            <tr>
              <th className="cb-area-col fixed-col fixed-col-1 "></th>
              <th className="sl-no-col text-grayText fixed-col fixed-col-2">
                Sl No.
              </th>
              <th className="transporter-col text-left text-grayText fixed-col fixed-col-3">
                Transporter
              </th>
              {uniqueCombinations.map((combo) => {
                const totalFilledCount = selectedTransporters.reduce(
                  (sum, transporterId) => {
                    return sum + (inputValues[transporterId]?.[combo] || 0);
                  },
                  0
                );
                const expectedCount =
                  inventoryData.find(
                    (item) => `${item.size}-${item.type}` === combo
                  )?.count || 0;
                return (
                  <th key={combo} className="container-size-col text-grayText">
                    {combo.replace("-", " ")}{" "}
                    <span
                      className={
                        totalFilledCount > expectedCount ||
                        totalFilledCount === 0
                          ? `excess_error`
                          : totalFilledCount === expectedCount
                          ? "perfect"
                          : totalFilledCount > 0 &&
                            totalFilledCount < expectedCount
                          ? "shortage_error"
                          : ""
                      }
                    >
                      ({totalFilledCount}/{expectedCount})
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="rounded-xl">
            {transporterData.map((transporter, index) => (
              <tr
                key={transporter.id}
                className={`bg-gray-100 ${
                  selectedTransporters.includes(transporter.id) &&
                  "bg-smallCardBackground"
                }`}
              >
                <td className=" border-b border-gray-200 text-center fixed-col fixed-col-1">
                  <input
                    type="checkbox"
                    checked={
                      selectedTransporters.includes(transporter.id) || false
                    }
                    onChange={() => handleCheckboxChange(transporter.id)}
                    className="form-checkbox h-5 w-5 accent-[#19B5FE] cursor-pointer mt-[8px]"
                  />
                </td>
                <td className=" font-bold border-b border-gray-200 text-center text-transporterText fixed-col fixed-col-2">
                  {index + 1}
                </td>
                <td className="transporter-name  font-bold border-b border-gray-200 text-left text-transporterText fixed-col fixed-col-3">
                  {transporter.name}
                </td>
                {uniqueCombinations.map((combo) => {
                  return (
                    <td
                      key={combo}
                      className="font-bold border-b border-gray-200"
                    >
                      {selectedTransporters.includes(transporter.id) ? (
                        <input
                          type="number"
                          min={0}
                          placeholder="Enter Qty"
                          value={inputValues[transporter.id]?.[combo] ?? ""}
                          onChange={(e) =>
                            handleInputChange(
                              transporter.id,
                              combo,
                              e.target.value
                            )
                          }
                          className="w-full container-count"
                        />
                      ) : (
                        <input
                          type="number"
                          min={0}
                          placeholder="Enter Qty"
                          disabled
                          className="w-full container-count"
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default Transporter;
