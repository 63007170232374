export const getPageTitle = (pathname: string): string => {
  switch (pathname) {
    case "/":
      return "Home";
    case "/dashboard":
      return "Dashboard";
    case "/jobs":
      return "Jobs";
    case "/trip-history":
      return "Trip History";
    case "/transporters":
      return "Transporters";
    case "/profile":
      return "Profile";
    case "/settings":
      return "Settings";
    default:
      return "";
  }
};

export const getSubPageTitle = (pathname: string): string => {
  if (pathname.includes("/assign-containers")) {
    return "Assign Containers";
  } else if (false) {
    // dummy else if ladder
    return "";
  } else {
    return "";
  }
};
