import React from 'react'
import IndividualJob from '../../components/TripHistory/IndividualJob'

const TripHistoryJob = () => {
  return (
    <div>
        <IndividualJob/>
    </div>
  )
}

export default TripHistoryJob