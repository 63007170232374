import React from "react";

interface LoadingBarProps {
  isLoading: boolean;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ isLoading }) => {
  return (
    <div
      className={`fixed top-0 left-0 w-full h-1 bg-blueText transition-transform ease-linear ${
        isLoading ? "animate-loading" : "hidden"
      }`}
    ></div>
  );
};

export default LoadingBar;
