import { Outlet } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import Header from "../components/Header/Header";
import { useEffect, useState } from "react";

const SidebarLayout: React.FC = () => {
  const [currentSegment, setCurrentSegment] = useState<string>("New");
  const handleSegmentChange = (segment: string) => {
    setCurrentSegment(segment);
  };
  useEffect(() => {
    // console.log("SidebarLayout currentSegment:", currentSegment); // Confirm segment change
  }, [currentSegment]);
  return (
    <div style={styles.layoutContainer}>
      <SideBar />
      <div style={styles.content}>
        <Header
          onSegmentChange={handleSegmentChange}
          currentSegment={currentSegment}
        ></Header>
        <div className="main_area_inset_shadow">
          <Outlet
            context={{ currentSegment, setSegment: handleSegmentChange }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  layoutContainer: {
    display: "flex",
  },
  content: {
    flex: 1,
  },
};

export default SidebarLayout;
