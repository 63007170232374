import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import { useLoader } from "../../contexts/LoaderContext";
import { ReactComponent as RingsLoader } from "../../assets/animated/rings.svg";

const FullPageLoader: React.FC = () => {
  const { isLoading, message } = useLoader();

  if (!isLoading) return null;

  return (
    <Box sx={styles.overlay}>
      <Box sx={styles.loader}>
        <RingsLoader className="w-[150px] h-[150px] text-blueText" />
        {/* <CircularProgress color="primary" size={150} /> */}
        <Typography sx={styles.message}>{message}</Typography>
      </Box>
    </Box>
  );
};

export default FullPageLoader;

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
  },
  loader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    marginTop: 2,
    color: "#ffffff",
    fontSize: 18,
  },
};
