import { api } from "../AxiosInstance";

// Define service functions for general data related API calls
const MetaDataService = {
  getContainerSizes: async () => {
    try {
      const response = await api.get("/master/container-sizes");
      return response;
    } catch (error) {
      console.error("Error getMyJobs:", error);
      throw error;
    }
  },

  getContainerTypes: async () => {
    try {
      const response = await api.get("/master/container-types");
      return response;
    } catch (error) {
      console.error("Error getMyJobs:", error);
      throw error;
    }
  },
};

export default MetaDataService;
