import "./SignUp.scss";

import React, { useState, FormEvent } from "react";
import axios from "axios";
import logo from "../../assets/st-logo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoading } from "../../contexts/LoadingContext";

const baseURL = process.env.REACT_APP_BASE_URL;

interface FormValues {
  fullName: string;
  companyName: string;
  companyGst: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  fullName: Yup.string().required("Name is required"),
  companyName: Yup.string().required("Company Name is required"),
  companyGst: Yup.string().required("Company GST number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().required("Password is required"),
});

const SignUp: React.FC = () => {
  const loader = useLoading();

  const initialValues: FormValues = {
    fullName: "",
    companyName: "",
    companyGst: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values: FormValues) => {
    console.log(values);
    // loader.setLoading(true);
    // try {
    //   const url = baseURL + "/common/login";
    //   const response = await axios(url, {
    //     method: "POST",
    //     data: {
    //       email: values.email,
    //       password: values.password,
    //     },
    //   });
    //   setTimeout(() => {
    //     loader.setLoading(false);
    //   }, 1000);
    // } catch (err) {
    //   console.error("Error:", err);
    //   setTimeout(() => {
    //     loader.setLoading(false);
    //     // showToast("Incorrect email or password ! Please try again", "error");
    //   }, 300);
    // }
  };

  return (
    <div className="signup-page flex items-center justify-center h-screen ambient-bkg">
      <div className="card-container flex flex-col items-center justify-center">
        <div className="logo-container ">
          <img src={logo} alt="Smart Transport Logo" />
        </div>

        <div className="form-container">
          <div className="header-cover text_align_center">
            <div className="header-text">Create Account</div>
            <div className="sub-header-text">Please fill your details</div>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <div className="form-field-cover">
                  <div className="form-group">
                    <div className="form-field">
                      <label
                        htmlFor="fullName"
                        className="required_field_label"
                      >
                        Full Name
                      </label>
                      <Field
                        type="text"
                        name="fullName"
                        placeholder="Enter your full name"
                        className={
                          touched.fullName && errors.fullName
                            ? "invalid_field"
                            : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div></div>

                  <div className="form-group">
                    <div className="form-field">
                      <label
                        htmlFor="companyName"
                        className="required_field_label"
                      >
                        Company Name
                      </label>
                      <Field
                        type="text"
                        name="companyName"
                        placeholder="Enter your Company Name"
                        className={
                          touched.companyName && errors.companyName
                            ? "invalid_field"
                            : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <label
                        htmlFor="companyGst"
                        className="required_field_label"
                      >
                        Company GST
                      </label>
                      <Field
                        type="text"
                        name="companyGst"
                        placeholder="Enter your Company GST"
                        className={
                          touched.companyGst && errors.companyGst
                            ? "invalid_field"
                            : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="companyGst"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <label htmlFor="phone" className="required_field_label">
                        Phone
                      </label>
                      <Field
                        type="text"
                        name="phone"
                        placeholder="Enter your phone number"
                        autoComplete="phone"
                        className={
                          touched.phone && errors.phone ? "invalid_field" : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <label htmlFor="email" className="required_field_label">
                        Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        autoComplete="email"
                        className={
                          touched.email && errors.email ? "invalid_field" : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <label
                        htmlFor="password"
                        className="required_field_label"
                      >
                        Password
                      </label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        className={
                          touched.password && errors.password
                            ? "invalid_field"
                            : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <label
                        htmlFor="confirmPassword"
                        className="required_field_label"
                      >
                        Confirm Password
                      </label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder="Enter your password"
                        className={
                          touched.password && errors.password
                            ? "invalid_field"
                            : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="validation_error"
                    />
                  </div>
                </div>

                <button
                  className="primary_button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Sign Up
                </button>

                <div className="footer_msg">
                  Already have an account? <a href="/sign-in">Sign In</a>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
