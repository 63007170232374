import React, { useState } from "react";
import {
  JobBreakUpDetail,
  JobOrder,
  TripContainer,
} from "../../interfaces/job.interface";
import { containerSizeStringToNumber } from "../../utils/general";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./JobBreakupTable.scss";
import JobService from "../../services/JobService";
import { useLoading } from "../../contexts/LoadingContext";
import { showToast } from "../Toaster";
import * as XLSX from "xlsx";

interface JobBreakupTableProps {
  job: JobOrder;
  onSave: (data: JobOrder) => void;
  onCancel: () => void;
}

// Validation schema for container numbers
const validationSchema = Yup.array().of(
  Yup.array().of(
    Yup.object().shape({
      containerNumber: Yup.string()
        .matches(/^[A-Z]{4}\d{7}$/, "Invalid Container Number")
        .max(11, "Cannot exceed 11 characters")
        .nullable(),
    })
  )
);

const JobBreakupTable: React.FC<JobBreakupTableProps> = ({
  job,
  onSave,
  onCancel,
}) => {
  // State for tracking original and edited data separately
  const [originalJobBreakupDetails, setOriginalJobBreakupDetails] = useState<
    JobBreakUpDetail[][]
  >(job.jobBreakups.map((b) => b.jobBreakUpDetails));
  const [editedJobBreakupDetails, setEditedJobBreakupDetails] = useState<
    JobBreakUpDetail[][]
  >(JSON.parse(JSON.stringify(originalJobBreakupDetails))); // Deep copy to avoid mutation

  const loader = useLoading();

  // Formik setup
  const formik = useFormik({
    initialValues: editedJobBreakupDetails,
    validationSchema,
    onSubmit: () => {
      onSave(job); // Save only if form is valid
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  // Stats calculation function
  const calculateStats = (details: JobBreakUpDetail[][]) => {
    const totalContainers = details.flat().length;
    const assignedContainers = details
      .flat()
      .filter((detail) => detail.containerNumber).length;
    const pendingContainers = totalContainers - assignedContainers;

    return {
      totalContainers,
      assignedContainers,
      pendingContainers,
    };
  };

  // Initial stats
  const [stats, setStats] = useState(calculateStats(originalJobBreakupDetails));

  // Update the edited details on container number change
  const handleContainerNumberChange = (
    jobBreakupIndex: number,
    detailIndex: number,
    value: string
  ) => {
    const formattedValue = value.toUpperCase();
    const updatedDetails = [...editedJobBreakupDetails];
    updatedDetails[jobBreakupIndex][detailIndex].containerNumber =
      formattedValue;

    // Update Formik's state
    setEditedJobBreakupDetails(updatedDetails);
    formik.setFieldValue(
      `${jobBreakupIndex}[${detailIndex}].containerNumber`,
      formattedValue
    );

    // Revalidate the specific field and clear error if valid
    if (/^[A-Z]{4}\d{7}$/.test(formattedValue)) {
      formik.setFieldError(
        `${jobBreakupIndex}[${detailIndex}].containerNumber`,
        ""
      );
    }
  };

  function generateTripContainerList(): TripContainer[] {
    return editedJobBreakupDetails.flatMap((breakupDetails, breakupIndex) =>
      breakupDetails
        .filter(
          (detail, detailIndex) =>
            detail.containerNumber &&
            !originalJobBreakupDetails[breakupIndex][detailIndex]
              .containerNumber
        )
        .map((detail) => ({
          tripId: detail.jobBreakupDetailId,
          containerNumber: detail.containerNumber as string,
        }))
    );
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json<any>(worksheet, { header: 1 });

        // Extract and log container information
        rows.slice(1).forEach((row: any) => {
          const containerNumber = row[0];
          const containerSize = row[1];
          const containerType = row[2];
          console.log("Container Number:", containerNumber);
          console.log("Container Size:", containerSize);
          console.log("Container Type:", containerType);
        });
        const parsedData = rows.slice(1).map((row: any) => ({
          containerNumber: row[0],
          containerSize: row[1],
          containerType: row[2],
        }));

        // Clear file input

        // setUploadedData(parsedData);
        console.log(parsedData);

        event.target.value = "";
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      const tripList = generateTripContainerList();
      if (tripList.length > 0) {
        try {
          loader.setLoading(true);
          const res = await JobService.assignContainers(tripList);
          const updatedJob = res.data.data;
          showToast(
            `Successfully assigned the containers to Job #${job.jobCode}`,
            "success"
          );
          onSave(updatedJob);
          const updatedStats = calculateStats(editedJobBreakupDetails);
          setStats(updatedStats); // Update stats state
          // setOriginalJobBreakupDetails(editedJobBreakupDetails);
          closeModal();
        } catch (error) {
          console.error("Error assigning containers", error);
          showToast(
            `Failed to assign the containers, please try again !`,
            "error"
          );
        } finally {
          setTimeout(() => {
            loader.setLoading(false);
          }, 1000);
        }
      } else {
        closeModal();
      }
    } else {
      console.log("Invalid form", errors);
      showToast(`Please provide valid container numbers !`, "error");
    }
  };

  const closeModal = () => {
    onCancel();
  };

  return (
    <div className="job-breakup">
      <form>
        <div className="breakup-table-container">
          <table>
            <thead>
              <tr>
                <th className="sl_no">Sl No.</th>
                <th className="container_no">Container Number</th>
                <th className="sity">Size & Type</th>
                <th className="transporter_name">Transporter</th>
              </tr>
            </thead>
            <tbody>
              {job.jobBreakups.map((breakup, breakupIndex) => {
                const offset = job.jobBreakups
                  .slice(0, breakupIndex)
                  .reduce(
                    (total, current) =>
                      total + current.jobBreakUpDetails.length,
                    0
                  );
                return editedJobBreakupDetails[breakupIndex].map(
                  (detail, detailIndex) => (
                    <tr key={detail.jobBreakupDetailId}>
                      {/* Calculate serial number using offset */}
                      <td className="sl_no">{offset + detailIndex + 1}</td>
                      <td className="container_no">
                        <input
                          maxLength={11}
                          type="search"
                          value={detail.containerNumber || ""}
                          onChange={(e) =>
                            handleContainerNumberChange(
                              breakupIndex,
                              detailIndex,
                              e.target.value
                            )
                          }
                          placeholder="Enter Container Number"
                          disabled={
                            !!originalJobBreakupDetails[breakupIndex][
                              detailIndex
                            ].containerNumber // Disable input only if originally filled
                          }
                        />
                        {formik.errors &&
                          (formik.errors[breakupIndex] as any)?.[detailIndex]
                            ?.containerNumber && (
                            <div className="validation_error">
                              {
                                (formik.errors[breakupIndex] as any)[
                                  detailIndex
                                ].containerNumber
                              }
                            </div>
                          )}
                      </td>
                      <td className="sity">
                        {containerSizeStringToNumber(detail.containerSize)}{" "}
                        {detail.containerType}
                      </td>
                      <td>{detail.transporterIdName.name || "N/A"}</td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="w-full flex justify-center mt-[20px] gap-[20px]">
          <button
            type="button"
            className="secondary_button w-[128px]"
            onClick={closeModal}
          >
            Cancel
          </button>

          {/* <div className=" flex justify-center gap-[20px]">
            <label htmlFor="file-upload" className="primary_button w-[128px]">
              Upload File
            </label>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              id="file-upload"
              className="hidden"
            />
          </div> */}

          <button
            type="button"
            onClick={handleSave}
            className="primary_button w-[128px]"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default JobBreakupTable;
