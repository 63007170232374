import React from "react";
import TripHistoryComponent from "../../components/TripHistory/TripHistoryComponent";
import ComingSoon from "../../components/ComingSoon/ComingSoon";

const TripHistory = () => {
  return (
    <div>
      <ComingSoon></ComingSoon>
      {/* <TripHistoryComponent /> */}
    </div>
  );
};

export default TripHistory;
