import "./ForgotPassword.scss";

import React, { useState } from "react";
import axios from "axios";
import forgot_password_mail_sent from "../../assets/images/forgot_password_mail_sent.png";
import { showToast } from "../../components/Toaster";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoading } from "../../contexts/LoadingContext";

const baseURL = process.env.REACT_APP_BASE_URL;

interface FormValues {
  email: string;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgotPassword: React.FC = () => {
  const loader = useLoading();
  const initialValues: FormValues = {
    email: "",
  };
  const [mailId, setMailId] = useState<string | null>(null);
  const [mailSent, setMailSent] = useState<boolean>(false);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    setMailId(values.email);
    loader.setLoading(true);
    try {
      const url = baseURL + "/common/forgot-password";
      const response = await axios(url, {
        method: "POST",
        data: {
          email: values.email,
        },
      });
      // setMailSent(true);
      setTimeout(() => {
        loader.setLoading(false);
      }, 1000);
    } catch (err) {
      setMailSent(true);
      console.error("Error:", err);
      setTimeout(() => {
        loader.setLoading(false);
        // showToast("Email not found", "error");
      }, 300);
    }
  };

  return (
    <div className="forgot-password-page flex items-center justify-center h-screen ambient-bkg">
      <div className="card-container flex flex-col items-center justify-center">
        {!mailSent ? (
          <div className="form-container">
            <div className="header-cover text_align_center">
              <div className="header-text">Forgot Password</div>
              <div className="sub-header-text">
                Please enter your email address
              </div>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <div className="form-group">
                    <div className="form-field">
                      <label htmlFor="email" className="required_field_label">
                        Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        autoComplete="email"
                        className={
                          touched.email && errors.email ? "invalid_field" : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <button
                    className="primary_button"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Proceed
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="email_sent_form text_align_center">
            <img
              className="success_email_banner"
              src={forgot_password_mail_sent}
              alt="Email Sent"
            />
            <div className="success_msg">
              An email has been sent to{" "}
              <span className="primary_color">{mailId}</span> with instructions
              for resetting the password. Please check your spam folder if you
              don't see an email in your inbox.
            </div>
            <div className="hint_msg">
              You can close this browser window now and continue the reset
              password process from mail.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
