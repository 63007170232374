import "./Jobs.scss";
import NewJob from "../../components/Jobs/NewJob";
import ExistingJob from "../../components/Jobs/ExistingJob";
import { useOutletContext } from "react-router-dom";
import { JobProvider } from "../../contexts/JobContext";

interface JobsProps {
  currentSegment: string;
  setSegment: (segment: string) => void;
}

export default function Jobs() {
  const { currentSegment, setSegment } = useOutletContext<JobsProps>();

  return (
    <JobProvider>
      <div className="jobs-page">
        <div className="jobs-container">
          <div className={currentSegment === "New" ? "visible" : "hidden"}>
            <NewJob setSegment={setSegment} />
          </div>
          <div className={currentSegment === "Existing" ? "visible" : "hidden"}>
            <ExistingJob currentSegment={currentSegment} />
          </div>
        </div>
      </div>
    </JobProvider>
  );
}
