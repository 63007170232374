import "./NewJob.scss";
import React, { useState, useEffect, useRef } from "react";
import Vessel from "../Vessel/Vessel";
import Transporter from "./../Transporter";
import Preview from "./Preview";
import CustomSelect, { CustomSelectRef } from "../CoreComponents/CustomSelect";
import GoogleAutoComplete, {
  GoogleAutoCompleteRef,
} from "../GoogleAutoComplete/GoogleAutoComplete";
import * as yup from "yup";
import { useMetadata } from "../../contexts/MetadataContext";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import {
  Job,
  TransporterJobHandlingRequest,
} from "../../interfaces/job.interface";
import { Inventory } from "../../interfaces/inventory.interface";
import { useLoading } from "../../contexts/LoadingContext";
import { showToast } from "../Toaster";
import { useJobContext } from "../../contexts/JobContext";
import JobService from "../../services/JobService";

interface NewJobProps {
  setSegment?: (segment: string) => void;
}

const NewJob: React.FC<NewJobProps> = ({ setSegment }) => {
  const loader = useLoading();
  const formikRef = useRef<FormikProps<Job>>(null);
  const jobTypeRef = useRef<CustomSelectRef>(null);
  const cycleRef = useRef<CustomSelectRef>(null);
  const googleAutoCompleteRefSource = useRef<GoogleAutoCompleteRef>(null);
  const googleAutoCompleteRefDest = useRef<GoogleAutoCompleteRef>(null);
  const vesselRef = useRef<{
    resetVesselData: () => any;
  }>(null);
  const transporterRef = useRef<{
    generateTransporterJobHandlingRequests: () => any;
    resetData: () => any;
  }>(null);
  const { jobTypes, movementCycleTypes } = useMetadata();
  const [activeSection, setActiveSection] = useState("vessel");
  const [inventoryData, setInventoryData] = useState<Inventory[]>([]);
  const [inventoryError, setInventoryError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const { triggerJobFetch } = useJobContext();

  const initialValues: Job = {
    jobCode: "",
    source: "",
    destination: "",
    sourceLatitude: "",
    sourceLongitude: "",
    destLatitude: "",
    destLongitude: "",
    jobDescription: "",
    jobType: "",
    service: "",
    vesselName: "",
    voyageVia: "",
    voa: "",
    cycle: "",
    gateOpenDate: "",
    gateCloseDate: "",
    transporterJobHandlingRequests: [],
  };

  const validationSchema = yup.object({
    jobCode: yup.string().required("Job Code is required"),
    source: yup.string().required("Source is required"),
    destination: yup.string().required("Destination is required"),
    cycle: yup.string().required("Cycle is required"),
    jobDescription: yup.string().required("Job Description is required"),
    jobType: yup.string().required("Job Type is required"),
    service: yup.string().required("Service is required"),
    vesselName: yup.string().required("Vessel Name is required"),
    voyageVia: yup.string().required("Voyage Via is required"),
    voa: yup.string().required("VOA is required"),
    gateOpenDate: yup
      .number()
      .required("Gate Open Date & Time is required")
      .typeError("Gate Open Date must be a valid timestamp"),
    gateCloseDate: yup
      .number()
      .required("Gate Close Date & Time is required")
      .typeError("Gate Close Date must be a valid timestamp"),
    transporterJobHandlingRequests: yup
      .array()
      .of(
        yup.object().shape({
          transporterId: yup.number().required("Transporter ID is required"),
          countTypeSizeRequestList: yup.array().of(
            yup.object().shape({
              count: yup.number().required("Count is required"),
              containerType: yup
                .string()
                .required("Container Type is required"),
              containerSize: yup
                .string()
                .required("Container Size is required"),
            })
          ),
        })
      )
      .required("At least one request is required"),
  });

  const handleSetTouchedAndValidate = async () => {
    if (formikRef.current) {
      const allFieldsTouched = Object.keys(formikRef.current.values).reduce(
        (acc, key) => {
          acc[key] = true;
          return acc;
        },
        {} as { [key: string]: boolean }
      );
      await formikRef.current.setTouched(allFieldsTouched);
      await formikRef.current.validateForm();
    }
  };

  const handleNext = () => {
    handleSetTouchedAndValidate();
    if (inventoryData.length > 0) {
      handleSectionChange("transporter");
    } else {
      console.log("show error");
      setInventoryError(true);
    }
  };

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const getTransporterJobHandlingRequests = () => {
    if (transporterRef.current) {
      const transporterRequests =
        transporterRef.current.generateTransporterJobHandlingRequests();
      return transporterRequests;
    } else {
      return [];
    }
  };

  const handleSubmit = (values: Job) => {
    const transporterJobHandlingRequests = getTransporterJobHandlingRequests();
    const totalContainers = inventoryData.reduce(
      (sum, item) => sum + item.count,
      0
    );
    const totalAssignedContainers = transporterJobHandlingRequests.reduce(
      (total: number, transporter: TransporterJobHandlingRequest) => {
        const countFromCurrentTransporter =
          transporter.countTypeSizeRequestList.reduce((subTotal, request) => {
            return subTotal + request.count;
          }, 0);
        return total + countFromCurrentTransporter;
      },
      0
    );
    if (totalContainers === totalAssignedContainers) {
      values.transporterJobHandlingRequests = transporterJobHandlingRequests;
      const temp: any = JSON.parse(JSON.stringify(values));
      delete temp["jobId"]; // fix this issue from backend
      createJob(temp);
    } else {
      console.error("error !");
      showToast(`Please assign all containers before submitting !`, "error");
    }
  };

  const createJob = async (job: Job) => {
    loader.setLoading(true);
    try {
      const res = await JobService.createJob(job);
      if (res.data.error) {
        showToast(res.data.message, "error");
      } else {
        showToast("Job created successfully !", "success");
        resetJobForm();
        triggerJobFetch();
        if (setSegment) setSegment("Existing");
      }
      loader.setLoading(false);
    } catch (error) {
      console.log("Error creating job", error);
      loader.setLoading(false);

      showToast("Failed to create the job, please try again !", "error");
    }
  };

  const resetJobForm = () => {
    formikRef.current?.resetForm();
    jobTypeRef.current?.reset();
    cycleRef.current?.reset();
    googleAutoCompleteRefSource.current?.reset();
    googleAutoCompleteRefDest.current?.reset();
    vesselRef.current?.resetVesselData();
    transporterRef.current?.resetData();
    setActiveSection("vessel");
  };

  useEffect(() => {
    if (inventoryData.length > 0) {
      setInventoryError(false);
    }
  }, [inventoryData]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openConfirmationModal = () => setIsConfirmationModalOpen(true);
  const closeConfirmationModal = () => setIsConfirmationModalOpen(false);

  return (
    <div className="new-job-page flex p-[20px]">
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid,
          dirty,
        }) => (
          <Form>
            <div className="form-cover">
              <div className="left-panel mr-[20px] ">
                <div className="w-full bg-card rounded-[10px] card-shadow p-[16px]">
                  <div className="form-group">
                    <div className="form-field">
                      <label htmlFor="jobType" className="required_field_label">
                        Job Type
                      </label>
                      <CustomSelect
                        ref={jobTypeRef}
                        onBlurred={() => {
                          setFieldTouched("jobType", true);
                        }}
                        className={`h-[40px] ${
                          touched.jobType && errors.jobType
                            ? "invalid_field"
                            : "default_border"
                        }`}
                        options={jobTypes}
                        isMulti={false}
                        label="Job Type"
                        placeholder="Select Job Type"
                        onChange={(selected) => {
                          setFieldValue("jobType", selected);
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="jobType"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <label htmlFor="jobCode" className="required_field_label">
                        Job Code
                      </label>
                      <Field
                        type="text"
                        name="jobCode"
                        placeholder="Enter Job Code"
                        className={
                          touched.jobCode && errors.jobCode
                            ? "invalid_field"
                            : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="jobCode"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <label htmlFor="source" className="required_field_label">
                        Source
                      </label>
                      <GoogleAutoComplete
                        ref={googleAutoCompleteRefSource}
                        onBlurred={() => {
                          setFieldTouched("source", true);
                          setFieldTouched("sourceLatitude", true);
                          setFieldTouched("sourceLongitude", true);
                        }}
                        className={
                          touched.source && errors.source ? "invalid_field" : ""
                        }
                        placeholder="Enter Source"
                        onPlaceSelected={(
                          place: google.maps.places.PlaceResult
                        ) => {
                          const address = place.name || "";
                          const lat = place.geometry?.location?.lat() || null;
                          const lng = place.geometry?.location?.lng() || null;
                          setFieldValue("source", address);
                          setFieldValue("sourceLatitude", lat?.toString());
                          setFieldValue("sourceLongitude", lng?.toString());
                          setFieldTouched("sourceLatitude", true);
                          setFieldTouched("sourceLongitude", true);
                        }}
                        onCleared={() => {
                          setFieldValue("source", "");
                          setFieldValue("sourceLatitude", "");
                          setFieldValue("sourceLongitude", "");
                          setFieldTouched("sourceLatitude", true);
                          setFieldTouched("sourceLongitude", true);
                        }}
                      ></GoogleAutoComplete>
                    </div>
                    <ErrorMessage
                      name="source"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <label
                        htmlFor="destination"
                        className="required_field_label"
                      >
                        Destination
                      </label>
                      <GoogleAutoComplete
                        ref={googleAutoCompleteRefDest}
                        onBlurred={() => {
                          setFieldTouched("destination", true);
                          setFieldTouched("destLatitude", true);
                          setFieldTouched("destLongitude", true);
                        }}
                        className={
                          touched.destination && errors.destination
                            ? "invalid_field"
                            : ""
                        }
                        placeholder="Enter Destination"
                        onPlaceSelected={(
                          place: google.maps.places.PlaceResult
                        ) => {
                          const address = place.name || "";
                          const lat = place.geometry?.location?.lat() || null;
                          const lng = place.geometry?.location?.lng() || null;
                          setFieldValue("destination", address);
                          setFieldValue("destLatitude", lat?.toString());
                          setFieldValue("destLongitude", lng?.toString());
                          setFieldTouched("destLatitude", true);
                          setFieldTouched("destLongitude", true);
                        }}
                        onCleared={() => {
                          setFieldValue("destination", "");
                          setFieldValue("destLatitude", "");
                          setFieldValue("destLongitude", "");
                          setFieldTouched("destLatitude", true);
                          setFieldTouched("destLongitude", true);
                        }}
                      ></GoogleAutoComplete>
                    </div>
                    <ErrorMessage
                      name="destination"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group no-mb">
                    <div className="form-field">
                      <label htmlFor="cycle" className="required_field_label">
                        Cycle
                      </label>
                      <CustomSelect
                        ref={cycleRef}
                        onBlurred={() => {
                          setFieldTouched("cycle", true);
                        }}
                        className={`h-[40px] ${
                          touched.cycle && errors.cycle
                            ? "invalid_field"
                            : "default_border"
                        }`}
                        options={movementCycleTypes}
                        isMulti={false}
                        label="Movement Cycle"
                        placeholder="Select Movement Cycle"
                        onChange={(selected) => {
                          setFieldValue("cycle", selected);
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="cycle"
                      component="div"
                      className="validation_error"
                    />
                  </div>
                </div>

                {activeSection === "transporter" && (
                  <div className="bg-card mt-[20px] rounded-[10px] card-shadow overflow-hidden">
                    <Preview inventoryData={inventoryData} />
                  </div>
                )}
              </div>

              <div className="right-panel bg-white rounded-[10px] card-shadow">
                <div className="flex flex-start	border-b border-bottomline m-b-[20px]">
                  <button
                    type="button"
                    className={`min-w-[100px] px-4 mx-[20px] min-h-[60px] font-w-[500] border-b-[1px] ${
                      activeSection === "vessel"
                        ? "border-blueText text-blueText"
                        : "text-notSelected  border-b-transparent"
                    }`}
                    onClick={() => handleSectionChange("vessel")}
                  >
                    Vessel & Inventory Details
                  </button>
                  <button
                    type="button"
                    className={`min-w-[100px] px-4 mx-[20px] min-h-[60px] font-w-[500]  border-b-[1px]  ${
                      activeSection === "transporter"
                        ? "border-blueText text-blueText"
                        : "text-notSelected border-b-transparent"
                    }`}
                    onClick={() => handleSectionChange("transporter")}
                  >
                    Transporter
                  </button>
                </div>
                <div
                  className={activeSection === "vessel" ? "visible" : "hidden"}
                >
                  <Vessel
                    ref={vesselRef}
                    setInventoryData={setInventoryData}
                    inventoryError={inventoryError}
                  />
                </div>
                <div
                  className={
                    activeSection === "transporter" ? "visible" : "hidden"
                  }
                >
                  <Transporter
                    inventoryData={inventoryData}
                    ref={transporterRef}
                  />
                </div>

                <div className="flex justify-center mt-2 gap-[10px] action-button-bar">
                  {/* <button
                    type="button"
                    onClick={() => console.log("Draft ?")}
                    className="secondary_button min-w-[160px]"
                  >
                    Save as Draft
                  </button> */}

                  <button
                    type="button"
                    onClick={handleNext}
                    className={
                      "secondary_button min-w-[160px] " +
                      (activeSection === "vessel" ? "visible" : "hidden")
                    }
                  >
                    Next
                  </button>

                  <button
                    type="submit"
                    className={
                      "primary_button min-w-[160px] " +
                      (activeSection === "transporter" ? "visible" : "hidden")
                    }
                    disabled={!isValid}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewJob;
