import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import MetaDataService from "../services/MetaDataService";
import { useAuth } from "./AuthContext";
import { containerSizeStringToNumber } from "../utils/general";

interface MetadataContextType {
  isMetaDataLoading: boolean;
  containerTypes: any;
  constainerSizes: any;
  movementCycleTypes: any;
  jobTypes: any;
  error: string | null;
}

const MetadataContext = createContext<MetadataContextType | undefined>(
  undefined
);

export const MetadataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();
  const [isMetaDataLoading, setIsMetaDataLoading] = useState(true);
  const [containerTypes, setContainerTypes] = useState<any>(null);
  const [constainerSizes, setConstainerSizes] = useState<any>(null);
  const [jobTypes, setJobTypes] = useState<any>(null);
  const [movementCycleTypes, setMovementCycleTypes] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const movementCycles = [
    { value: "EXPORT", label: "Export" },
    { value: "IMPORT", label: "Import" },
    { value: "GENERAL", label: "General" },
  ];

  const jobTypeOptions = [
    { value: "PICKUP", label: "Pick Up" },
    { value: "DROP", label: "Drop" },
  ];

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    setJobTypes(jobTypeOptions);
    setMovementCycleTypes(movementCycles);
    const fetchMetadata = async () => {
      try {
        const [response1, response2] = await Promise.all([
          MetaDataService.getContainerSizes(),
          MetaDataService.getContainerTypes(),
        ]);

        const transformedArray = response1.data.data.flatMap((item: string) => {
          const label = containerSizeStringToNumber(item);
          return [{ label: label, value: item }];
        });
        setConstainerSizes(transformedArray);

        const transformedContainerTypes = response2.data.data.map(
          (item: string, index: number) => ({
            label: item,
            value: item,
          })
        );
        setContainerTypes(transformedContainerTypes);
      } catch (err) {
        setError("Failed to fetch metadata");
      } finally {
        setIsMetaDataLoading(false);
      }
    };
    fetchMetadata();
  }, [isAuthenticated]);

  return (
    <MetadataContext.Provider
      value={{
        isMetaDataLoading,
        constainerSizes,
        containerTypes,
        jobTypes,
        movementCycleTypes,
        error,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};

export const useMetadata = () => {
  const context = useContext(MetadataContext);
  if (!context)
    throw new Error("useMetadata must be used within a MetadataProvider");
  return context;
};
