import "./index.scss";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { GoogleMapsProvider } from "./contexts/GoogleMapsContext";
import { LoadingProvider } from "./contexts/LoadingContext";
import { MetadataProvider } from "./contexts/MetadataContext";
import React from "react";
import { LoaderProvider } from "./contexts/LoaderContext";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <LoaderProvider>
      <FullPageLoader />
      <LoadingProvider>
        <AuthProvider>
          <GoogleMapsProvider>
            <MetadataProvider>
              <App />
            </MetadataProvider>
          </GoogleMapsProvider>
        </AuthProvider>
      </LoadingProvider>
    </LoaderProvider>
  </ThemeProvider>
  // </React.StrictMode>
);
