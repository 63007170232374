import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface AuthContextType {
  isAuthLoading: boolean;
  token: string | null;
  isAuthenticated: boolean;
  login: (token: string, refreshToken: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(() => {
    return localStorage.getItem("Authorization");
  });
  const [refreshToken, setRefreshToken] = useState<string | null>(() => null);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return !!localStorage.getItem("Authorization");
  });

  const [isAuthLoading, setAuthIsLoading] = useState(true); // Set initial loading state

  useEffect(() => {
    const storedToken = localStorage.getItem("Authorization");
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }
    setAuthIsLoading(false); // Loading complete
  }, []);

  const login = (authToken: string, refreshToken: string) => {
    localStorage.setItem("Authorization", authToken);
    localStorage.setItem("RefreshToken", refreshToken);
    setToken(authToken);
    setRefreshToken(refreshToken);
    setIsAuthenticated(true);
  };

  const logout = () => {
    console.log("logout from authContext");
    localStorage.removeItem("Authorization");
    localStorage.removeItem("RefreshToken");
    setToken(null);
    setRefreshToken(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthLoading, token, isAuthenticated, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");
  return context;
};
