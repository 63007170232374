import React from "react";
import "./ComingSoon.scss";
import { ReactComponent as ComingSoonImage } from "./../../assets/images/coming_soon.svg";

const ComingSoon: React.FC = () => {
  return (
    <div className="coming-soon-page flex flex-col items-center justify-center h-screen">
      <div className="card-container flex flex-col items-center justify-center">
        <ComingSoonImage />
        <div className="heading text_align_center">
          <div className="header">
            Get Ready for Lift-Off! This feature is coming soon !
          </div>
          <div className="sub_header">
            Our rocket is fueling up with some exciting new features, and we
            can't wait to launch them your way.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
