import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";

interface Option {
  value: string;
  label: string;
}

interface CustomSelectProps {
  options: Option[];
  isMulti?: boolean; // If true, allows multiple selection
  label: string;
  placeholder?: string;
  onChange: (selected: string | string[]) => void;
  className?: string;
  onBlurred?: () => void;
}

// Define the type of the methods that will be available on the ref
export interface CustomSelectRef {
  reset: () => void;
}

const CustomSelect = forwardRef<CustomSelectRef, CustomSelectProps>(
  (
    {
      options,
      isMulti = false,
      placeholder = "Select an option",
      onChange,
      className,
      onBlurred,
    }: CustomSelectProps,
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<string | string[]>(
      isMulti ? [] : ""
    );

    useImperativeHandle(ref, () => ({
      reset() {
        setSelectedValue(isMulti ? [] : "");
      },
    }));

    const handleChange = (event: SelectChangeEvent<string | string[]>) => {
      const { value } = event.target;
      const newValue = isMulti && Array.isArray(value) ? value : value;
      setSelectedValue(newValue);
      onChange(newValue);
    };

    const handleOnBlur = () => {
      if (onBlurred) onBlurred();
    };

    return (
      <FormControl
        sx={{
          "& .MuiOutlinedInput-root": {
            background: "#fff",
            fontSize: "16px",
            fontWeight: 600,
            borderRadius: "3px",
            "& fieldset": {
              border: "none",
            },
          },
        }}
      >
        <Select
          onBlur={handleOnBlur}
          multiple={isMulti}
          value={selectedValue}
          onChange={handleChange}
          className={className}
          displayEmpty
          renderValue={(selected) => {
            if (
              !selected ||
              (Array.isArray(selected) && selected.length === 0)
            ) {
              return placeholder;
            }
            if (isMulti && Array.isArray(selected)) {
              return selected
                .map(
                  (value) =>
                    options.find((option) => option.value === value)?.label ||
                    ""
                )
                .join(", ");
            }
            const selectedOption = options.find(
              (option) => option.value === selected
            );
            return selectedOption ? selectedOption.label : placeholder;
          }}
        >
          {options?.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              className="text-mainText font-bold text-[14px] h-[40px]"
            >
              {isMulti ? (
                <>
                  <Checkbox
                    checked={
                      Array.isArray(selectedValue) &&
                      selectedValue.includes(option.value)
                    }
                    className="text-blueText"
                  />
                  <ListItemText primary={option.label} />
                </>
              ) : (
                option.label
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

CustomSelect.displayName = "CustomSelect";

export default CustomSelect;
