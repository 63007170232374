import "./NotFound.scss";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NotFoundImage } from "./../../assets/images/404.svg";

const NotFound: React.FC = () => {
  return (
    <div className="not-found-page flex flex-col items-center justify-center h-screen ambient-bkg">
      <div className="card-container flex flex-col items-center justify-center">
        <NotFoundImage />
        <div className="heading text_align_center">
          <div className="header">Oops! The Page is Out of This World</div>
          <div className="sub_header">
            Our servers are on a cosmic popcorn break, and the page you're
            looking for is lost in space. But don't worry, we'll beam you back
            to safety!
          </div>
          <Link to="/">Go back to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
