import "./TransporterDetails.scss";
import React, { useEffect, useState } from "react";
import { ReactComponent as ContainerIcon } from "../assets/icons/container-icon.svg";
import { ReactComponent as TruckIcon } from "../assets/icons/truck.svg";
import { ReactComponent as LocationPin } from "../assets/icons/location-pin-icon.svg";
import { ReactComponent as LocationPinWithTick } from "../assets/icons/location-pin-with-tick.svg";
import { JobOrder } from "../interfaces/job.interface";
import { containerSizeStringToNumber } from "../utils/general";
import { Transporter } from "../interfaces/transporter.interface";

interface TransporterDetailsProps {
  jobOrder?: JobOrder;
}

interface JobBreakupDetail {
  transporterIdName: Transporter;
  containerType: string;
  containerSize: string;
  startDate?: string | null;
  endDate?: string | null;
}

interface TransporterInventory {
  transporterIdName: Transporter;
  inventoryDetails: {
    count: number;
    containerType: string;
    containerSize: string;
    assignedTrucks: number;
    completedTrips: number; // Add completedTrips to inventory details
  }[];
}

function getTransportersInventory(job: JobOrder): TransporterInventory[] {
  // Create a map to store transporter inventories
  const transporterMap = new Map<string, TransporterInventory>();

  // Process each job breakup
  job.jobBreakups.forEach((breakup) => {
    // Group job breakup details by transporter
    const transporterGroups = breakup.jobBreakUpDetails.reduce(
      (acc, detail) => {
        const transporterId = detail.transporterIdName.id.toString();

        if (!acc[transporterId]) {
          acc[transporterId] = {
            transporter: detail.transporterIdName,
            details: [],
            trucks: new Set<string>(), // Track unique truckIds
          };
        }

        acc[transporterId].details.push(detail);

        // Add truckId to the set of trucks (ensures uniqueness)
        if (detail.driverIdName) {
          acc[transporterId].trucks.add(detail.driverIdName.id.toString());
        }

        return acc;
      },
      {} as Record<
        string,
        {
          transporter: { id: string; name: string };
          details: JobBreakupDetail[];
          trucks: Set<string>; // Set to ensure unique trucks
        }
      >
    );

    // Calculate inventory for each transporter
    Object.values(transporterGroups).forEach(
      ({ transporter, details, trucks }) => {
        const transporterId = transporter.id.toString();

        // Get or create transporter inventory
        if (!transporterMap.has(transporterId)) {
          transporterMap.set(transporterId, {
            transporterIdName: {
              id: transporterId,
              name: transporter.name,
            },
            inventoryDetails: [],
          });
        }

        const inventory = transporterMap.get(transporterId)!;

        // Calculate count for this container type and size
        const count = details.length;

        // Find or create inventory detail
        const existingDetail = inventory.inventoryDetails.find(
          (detail) =>
            detail.containerType === breakup.containerType &&
            detail.containerSize === breakup.containerSize
        );

        // Count the unique trucks assigned for this transporter
        const assignedTrucks = trucks.size;

        // Count completed trips for the specific container type and size
        const completedTrips = details.filter(
          (detail) => detail.startDate && detail.endDate
        ).length;

        if (existingDetail) {
          existingDetail.count += count;
          existingDetail.assignedTrucks += assignedTrucks;
          existingDetail.completedTrips += completedTrips; // Add completed trips to the inventory
        } else {
          inventory.inventoryDetails.push({
            count,
            containerType: breakup.containerType,
            containerSize: breakup.containerSize,
            assignedTrucks: assignedTrucks,
            completedTrips, // Set completed trips for this inventory detail
          });
        }
      }
    );
  });

  return Array.from(transporterMap.values());
}

const TransporterDetails: React.FC<TransporterDetailsProps> = ({
  jobOrder,
}) => {
  const [transporters, setTransporters] = useState<TransporterInventory[]>([]);

  useEffect(() => {
    console.log("transporter details");
    console.log(jobOrder);
    if (jobOrder) {
      const transporters = getTransportersInventory(jobOrder);
      console.log(transporters);
      setTransporters(transporters);
    }
  }, [jobOrder]);

  return (
    <div className="transporter-details-component h-full overflow-y-auto pb-[123px]">
      {transporters.map((transporter: TransporterInventory, index) => (
        <div key={index} className="transporter_card">
          <div className="header">{transporter.transporterIdName.name}</div>
          <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 pb-[20px] m-[20px]">
            {transporter.inventoryDetails.map((inventory, index) => (
              <div
                key={index}
                className="trailer_category bg-card p-[20px] rounded-[8px]  text-grayText"
              >
                <div className="row">
                  <div className="left">
                    <ContainerIcon className="icon_style" />
                    <span>Inventory</span>
                  </div>
                  <div className="right">
                    {inventory.count} x{" "}
                    {containerSizeStringToNumber(inventory.containerSize)}{" "}
                    {inventory.containerType}
                  </div>
                </div>

                <div className="row">
                  <div className="left">
                    <TruckIcon className="icon_style" />
                    <span>Assigned Truck</span>
                  </div>
                  <div className="right">{inventory.assignedTrucks}</div>
                </div>

                <div className="row">
                  <div className="left">
                    <LocationPin className="icon_style" />
                    <span>Required Trip</span>
                  </div>
                  <div className="right">{inventory.count}</div>
                </div>

                <div className="row">
                  <div className="left">
                    <LocationPinWithTick className="icon_style" />
                    <span>Completed Trip</span>
                  </div>
                  <div className="right completed_trips">
                    {inventory.completedTrips > 0
                      ? inventory.completedTrips
                      : "N/A"}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TransporterDetails;
