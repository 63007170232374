import "./Preview.scss";
import React from "react";
import { useFormikContext } from "formik";
import { Inventory } from "../../interfaces/inventory.interface";
import { calculateCycleTime, formatEpochDate } from "../../utils/general";

interface PreviewProps {
  inventoryData: Inventory[];
}

const Preview: React.FC<PreviewProps> = ({ inventoryData }) => {
  const { values } = useFormikContext<any>();

  return (
    <div className="bg-card  text-left  rounded-[10px] card-shadow job-preview">
      <div className="main">
        <div className="row">
          <div className="label">Total Containers</div>
          <div className="value">
            {inventoryData.length === 0 && "N/A"}
            {inventoryData.length > 0 &&
              inventoryData.reduce((sum, item) => sum + item.count, 0)}
          </div>
        </div>
        <div className="row">
          <div className="label">Inventory</div>
          <div className="value">
            {inventoryData.length === 0 && "N/A"}
            {inventoryData.length > 0 &&
              inventoryData.map((row, index) => (
                // <span key={index}>
                //   {row.count} x {row.size} {row.type}, {row.count} x {row.size}{" "}
                //   {row.type},{" "}
                // </span>
                <div key={index}>
                  <div>
                    {row.count} x {row.size} {row.type}
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="row">
          <div className="label">Vessel Name</div>
          <div className="value">{values.vesselName || "N/A"}</div>
        </div>

        <div className="row">
          <div className="label">Service</div>
          <div className="value">{values.service || "N/A"}</div>
        </div>

        <div className="row">
          <div className="label">VOA</div>
          <div className="value">{values.voa || "N/A"}</div>
        </div>

        <div className="row">
          <div className="label">VOA / VIA</div>
          <div className="value">{values.voyageVia || "N/A"}</div>
        </div>

        <div className="row">
          <div className="label">Gate Open</div>
          <div className="value">
            {values.gateOpenDate
              ? formatEpochDate(values.gateOpenDate * 1000)
              : "N/A"}
          </div>
        </div>

        <div className="row">
          <div className="label">Cut Off</div>
          <div className="value">
            {values.gateCloseDate
              ? formatEpochDate(values.gateCloseDate * 1000)
              : "N/A"}
          </div>
        </div>
      </div>

      <div className="cycle_time">
        <div className="label">Cycle Time</div>
        <div className="value">
          {calculateCycleTime(
            values.gateOpenDate * 1000,
            values.gateCloseDate * 1000
          ) || "N/A"}
        </div>
      </div>
    </div>
  );
};

export default Preview;
