import React from 'react';
import ProfilePage from '../../components/Profile/ProfilePage';


const Profile = () => {
  return (
    <div>
     <ProfilePage/>
     
    </div>
  );
};

export default Profile;
