import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#19b5fe",
      // main: "#027cb5",
    },
    secondary: {
      // main: "#19b5fe",
      main: "#027cb5",
    },
    background: {
      default: "#f5f7f7",
    },
    text: {
      primary: "#59585b",
      secondary: "#838285",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            fontSize: 14,
            padding: "10px 16px",
            color: "#59585b",
            fontWeight: 500,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Custom border radius for buttons
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            color: "#59585b", // Custom text color for input fields
          },
          border: "solid 1px #cbd2d9",
          height: "40px",
          borderRadius: "3px",
          "& .MuiInputBase-input": {
            fontSize: 14,
            padding: "10px 16px",
            color: "#59585b",
            fontWeight: 500,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiInputLabel-animated": {
            display: "none",
          },
        },
      },
    },
  },
});

export default theme;
