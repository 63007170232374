import "./Vessel.scss";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CustomSelect from "../CoreComponents/CustomSelect";
import DateTimePickerComponent from "../DateTimePicker/DateTimePicker";
import { useMetadata } from "../../contexts/MetadataContext";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { containerSizeToString } from "../../utils/general";
import { Inventory } from "../../interfaces/inventory.interface";

interface VesselProps {
  setInventoryData: React.Dispatch<React.SetStateAction<any>>;
  inventoryError: boolean;
}

const Vessel = forwardRef<{ resetVesselData: () => any }, VesselProps>(
  ({ setInventoryData, inventoryError }, ref) => {
    const { containerTypes } = useMetadata();
    const { errors, touched, setFieldValue, setFieldTouched } =
      useFormikContext<any>();
    const [selectKey, setSelectKey] = useState(0);
    const [count, setCount] = useState<number>(0);
    const [size, setSize] = useState("");
    const [type, setType] = useState("");
    const [rows, setRows] = useState<Inventory[]>([]);
    const dateTimePickerOpenRef = useRef<{ reset: () => void }>(null);
    const dateTimePickerCloseRef = useRef<{ reset: () => void }>(null);

    const resetVesselData = () => {
      dateTimePickerOpenRef.current?.reset();
      dateTimePickerCloseRef.current?.reset();
      setRows([]);
      inventoryError = false;
    };

    useImperativeHandle(ref, () => ({
      resetVesselData,
    }));

    const handleAdd = () => {
      if (!count || !size || !type) return;

      setRows((prevRows) => {
        const existingRowIndex = prevRows.findIndex(
          (row) => row.size === size && row.type === type
        );

        if (existingRowIndex >= 0) {
          // If a row with the same Size and Type exists, update its Count
          const updatedRows = [...prevRows];
          updatedRows[existingRowIndex].count += count;
          return updatedRows;
        } else {
          // Otherwise, add a new row
          return [...prevRows, { count, size, type }];
        }
      });
      setCount(0);
      setSize("");
      setType("");
      setSelectKey((prev) => prev + 1);
    };

    const handleRemoveRow = (index: number) => {
      setRows((prevRows) => prevRows.filter((_, i) => i !== index));
    };

    useEffect(() => {
      generateDescription();
      setInventoryData(rows);
    }, [rows]);

    const generateDescription = () => {
      const jobDescription = rows
        .map(
          (row) =>
            `${row.count}-${containerSizeToString(parseInt(row.size))}-${
              row.type
            }`
        )
        .join(",");
      setFieldValue("jobDescription", jobDescription);
    };

    return (
      <div className="vessel-component p-[16px]">
        <div className="form-field-cover">
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="vesselName" className="required_field_label">
                Vessel Name
              </label>
              <Field
                type="text"
                name="vesselName"
                placeholder="Enter Vessel Name"
                className={
                  touched.vesselName && errors.vesselName ? "invalid_field" : ""
                }
              />
            </div>
            <ErrorMessage
              name="vesselName"
              component="div"
              className="validation_error"
            />
          </div>

          <div className="form-group">
            <div className="form-field">
              <label htmlFor="service" className="required_field_label">
                Service
              </label>
              <Field
                type="text"
                name="service"
                placeholder="Enter Service"
                className={
                  touched.service && errors.service ? "invalid_field" : ""
                }
              />
            </div>
            <ErrorMessage
              name="service"
              component="div"
              className="validation_error"
            />
          </div>

          <div className="form-group">
            <div className="form-field">
              <label htmlFor="voa" className="required_field_label">
                VOA
              </label>
              <Field
                type="text"
                name="voa"
                placeholder="Enter VOA"
                className={touched.voa && errors.voa ? "invalid_field" : ""}
              />
            </div>
            <ErrorMessage
              name="voa"
              component="div"
              className="validation_error"
            />
          </div>

          <div className="form-group">
            <div className="form-field">
              <label htmlFor="voyageVia" className="required_field_label">
                VOY / VIA
              </label>
              <Field
                type="text"
                name="voyageVia"
                placeholder="Enter VOY / VIA"
                className={
                  touched.voyageVia && errors.voyageVia ? "invalid_field" : ""
                }
              />
            </div>
            <ErrorMessage
              name="voyageVia"
              component="div"
              className="validation_error"
            />
          </div>

          <div className="form-group">
            <div className="form-field">
              <label htmlFor="gateOpenDate" className="required_field_label">
                Gate Open Date & Time
              </label>
              <DateTimePickerComponent
                autoSelectDateOption="today"
                ref={dateTimePickerOpenRef}
                onBlurred={() => {
                  setFieldTouched("gateOpenDate", true);
                }}
                className={`h-[40px]  
                  ${
                    touched.gateOpenDate && errors.gateOpenDate
                      ? "invalid_field"
                      : ""
                  }
                  `}
                onChange={(date: Date | null) => {
                  if (date) {
                    const unixTimestamp = new Date(date).getTime() / 1000;
                    setFieldValue("gateOpenDate", unixTimestamp);
                  } else {
                    setFieldValue("gateOpenDate", "");
                  }
                }}
              ></DateTimePickerComponent>
            </div>
            <ErrorMessage
              name="gateOpenDate"
              component="div"
              className="validation_error"
            />
          </div>

          <div className="form-group">
            <div className="form-field">
              <label htmlFor="gateCloseDate" className="required_field_label">
                Gate Close Date & Time
              </label>
              <DateTimePickerComponent
                autoSelectDateOption="tomorrow"
                ref={dateTimePickerCloseRef}
                onBlurred={() => {
                  setFieldTouched("gateCloseDate", true);
                }}
                className={`h-[40px]  
                  ${
                    touched.gateCloseDate && errors.gateCloseDate
                      ? "invalid_field"
                      : ""
                  }
                  `}
                onChange={(date: Date | null) => {
                  console.log(date);
                  if (date) {
                    const unixTimestamp = new Date(date).getTime() / 1000;
                    setFieldValue("gateCloseDate", unixTimestamp);
                  } else {
                    setFieldValue("gateCloseDate", "");
                  }
                }}
              ></DateTimePickerComponent>
            </div>
            <ErrorMessage
              name="gateCloseDate"
              component="div"
              className="validation_error"
            />
          </div>
        </div>

        <div className="form-group no-mb">
          <div className="form-field">
            <label htmlFor="gateOpenDate" className="required_field_label">
              Inventory
            </label>
          </div>
        </div>
        <div
          className={`bg-grayBack rounded-[10px] p-[20px] ${
            inventoryError ? "inventory_error" : ""
          }`}
        >
          <div className="flex justify-center gap-1">
            <div className="flex flex-col w-[128px]">
              <label className="block text-left text-grayText required_field_label">
                Count
              </label>
              <input
                type="number"
                name="count"
                min={0}
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                className="p-3.5 border border-gray-300 rounded h-[40px]"
                placeholder="Count"
                onFocus={(event) => {
                  if (parseInt(event.target.value) === 0) {
                    event.target.select();
                  }
                }}
              />
            </div>
            <div className="flex flex-col w-[92px]">
              <label className="block text-left text-grayText required_field_label">
                Size
              </label>
              <CustomSelect
                key={selectKey}
                className="h-[40px] default_border"
                onChange={(p) => setSize(p as string)}
                // options={constainerSizes}
                options={[
                  { value: "20", label: "20" },
                  { value: "40", label: "40" },
                  { value: "45", label: "45" },
                ]}
                label="Select Size"
                placeholder="Size"
                isMulti={false}
              />
            </div>
            <div className="flex flex-col w-[92px]">
              <label className="block text-left text-grayText required_field_label">
                Type
              </label>
              <CustomSelect
                key={selectKey}
                className="h-[40px] default_border"
                onChange={(p) => setType(p as string)}
                options={containerTypes}
                label="Select Type"
                isMulti={false}
                placeholder="Type"
              />
            </div>
          </div>
          <div className="flex justify-center my-[20px]">
            <button
              type="button"
              className="font-bold text-black rounded-[5px] border border-black h-[40px] w-[160px]"
              onClick={handleAdd}
            >
              Add
            </button>
          </div>
          {rows.length > 0 && (
            <div className="inventory-table">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="py-2 text-grayText">Count</th>
                    <th className="py-2 text-grayText">Size</th>
                    <th className="py-2 text-grayText">Type</th>
                    <th className="py-2 text-grayText"></th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td>{row.count}</td>
                      <td>{row.size}</td>
                      <td>{row.type}</td>
                      <td>
                        <span
                          className="link-style"
                          onClick={() => handleRemoveRow(index)}
                        >
                          Remove Item
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {inventoryError && (
          <div className="form-group no-mb">
            <div className="validation_error ">
              Add at least one inventory to proceed
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default Vessel;
