import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Logo from "../assets/logo.png";
import TransportersIcon from "../assets/transporter.png";
import JobsIcon from "../assets/jobs.png";
import ReportsIcon from "../assets/reports.png";
import ProfileIcon from "../assets/profile.png";
import SettingsIcon from "../assets/settings.png";
import HamburgerIcon from "../assets/hamburger.png";

const LeftSideNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Static Hamburger Button */}
      <button
        onClick={toggleNavbar}
        className="fixed top-4 left-4 focus:outline-none z-50"
      >
        <img
          src={HamburgerIcon}
          alt="Toggle Navbar"
          className={`transition-transform ${
            isOpen ? "" : "rotate-180"
          } w-12 h-12`} 
        />
      </button>

      {/* Navbar */}
      <div
        className={`h-auto fixed inset-y-0 left-0 transform ${
          isOpen ? "translate-x-0 " : "-translate-x-full"
        } transition-transform duration-300 ease-in-out flex flex-col w-auto text-black bg-card z-40`}
      >
        <div className="flex py-4 px-4 h-auto    items-center justify-between  border-b border-bottomline ml-10">
        <img src={Logo} alt="Smart Transport Logo" className="ml-5 mt-2" />
        </div>
        <nav className="flex-grow p-0 ">
          <ul className="space-y-4">
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }: { isActive: boolean }) =>
                  isActive
                    ? "flex items-center py-3 px-4 rounded bg-smallCardBackground text-cyan-400 font-bold text-left text-xl"
                    : "flex items-center py-3 px-4 rounded hover:bg-blue-400 text-left text-xl"
                }
              >
                <img
                  src={TransportersIcon}
                  alt="Transporters Icon"
                  className="mr-4 w-6 h-6"
                />
                Transporters
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/jobs"
                className={({ isActive }: { isActive: boolean }) =>
                  isActive
                    ? "flex items-center py-3 px-4 rounded bg-smallCardBackground text-cyan-400 font-bold text-left text-xl"
                    : "flex items-center py-3 px-4 rounded hover:bg-blue-400 text-left text-xl"
                }
              >
                <img src={JobsIcon} alt="Jobs Icon" className="mr-4 w-6 h-6" />
                Jobs
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/reports"
                className={({ isActive }: { isActive: boolean }) =>
                  isActive
                    ? "flex items-center py-3 px-4 rounded bg-smallCardBackground text-cyan-400 font-bold text-left text-xl"
                    : "flex items-center py-3 px-4 rounded hover:bg-blue-400 text-left text-xl"
                }
              >
                <img
                  src={ReportsIcon}
                  alt="Reports Icon"
                  className="mr-4 w-6 h-6"
                />
                Reports
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile"
                className={({ isActive }: { isActive: boolean }) =>
                  isActive
                    ? "flex items-center py-3 px-4 rounded bg-smallCardBackground text-cyan-400 font-bold text-left text-xl"
                    : "flex items-center py-3 px-4 rounded hover:bg-blue-400 text-left text-xl"
                }
              >
                <img
                  src={ProfileIcon}
                  alt="Profile Icon"
                  className="mr-4 w-9 h-9"
                />
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings"
                className={({ isActive }: { isActive: boolean }) =>
                  isActive
                    ? "flex items-center py-3 px-4 rounded bg-smallCardBackground text-cyan-400 font-bold text-left text-xl"
                    : "flex items-center py-3 px-4 rounded hover:bg-blue-400 text-left text-xl"
                }
              >
                <img
                  src={SettingsIcon}
                  alt="Settings Icon"
                  className="mr-4 w-9 h-9"
                />
                Settings
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default LeftSideNavbar;
